import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';
import { Resp } from '@sharedmodels/resp.model';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root',
})
export class OnboardingCompleteService {
  userUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    public globalErrorHandlerService: GlobalErrorHandlerService
  ) {}

  submitOnboarding() {
    return this.http
      .post(
        this.userUrl + '/user/submit-onboarding',
        {},
        {
          headers: new HttpHeaders({
            fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
          }),
        }
      )
      .pipe(
        map((res: Resp) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }
}
