import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  envUrl: string = environment.apiUrl;
  constructor(private http: HttpClient) { }

  logError(error: any) {
    return this.http.post(this.envUrl + '/stats/error', error).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {        
        return err;
      })
    );
  }
}
