<div class="col-12 col-lg-8" *ngIf="!loading">
  <div class="card m-3">
    <div class="card-header">
      <h2>Tak for oprettelse af kundeforhold hos Fundmarket</h2>
    </div>
    <div class="card-body">
      <p>Vi vil nu gennemgå dine oplysninger.</p>
      <p>Du modtager en e-mail, når vi har godkendt kundeforholdet.</p>

      <p>
        Efter godkendelsen skal du logge ind, gå ind under menuen Investering,
        vælge Investeringsvalg og angive din ønskede fordeling af
        investeringsfonde. Herefter er du klar til at investere.
      </p>
    </div>
  </div>
</div>
<app-spinner [spinning]="loading"></app-spinner>
