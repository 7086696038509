import { FocusMixin, html } from 'cedk';
import { Component } from '../../shared/component';

/**
 * <fm-button>
 * 
 */
export class Button extends FocusMixin(Component) {
  static define() {
    super.define('fm-button');
  }

  set loading(l) {
    const after = this.shadowRoot.querySelector('#after');
    if (l) {
      after.classList.add("spinner");
    } else {
      after.classList.remove("spinner");
    }
  }


  static get style() {
    return {
      ':host': {
        'display': 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'min-width': '100px',
        'border': 'none',
        'border-radius': '4px',
        'font-family': 'Roboto',
        'font-size': '14px',
        'text-transform': 'uppercase',
        'font-weight': '500',
        'padding': '12px 16px',
        'transition-duration': '0.3s',
        'transition-property': 'background-color, box-shadow',
        'transition-timing-function': 'cubic-bezier(0.25, 0.8, 0.25, 1)',
        'background': 'var(--primary-color, #00b96f)',
        'color': '#fff',
        'white-space': 'nowrap',
        'box-shadow': [
          '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
          '0 1px 5px 0 rgba(0, 0, 0, 0.12)',
          '0 3px 1px -2px rgba(0, 0, 0, 0.2)'
        ].join(',')
      },
      ':host[hidden]': {
        'display': 'none'
      },
      ':host(:not([disabled]))': {
        'cursor': 'pointer'
      },
      ':host(:hover), :host(:focus)': {
        'box-shadow': [
          '0 4px 5px 0 rgba(0, 0, 0, 0.14)',
          '0 1px 10px 0 rgba(0, 0, 0, 0.12)',
          '0 2px 4px -1px rgba(0, 0, 0, 0.4)'
        ].join(',')
      },
      ':host([light])': {
        'background': 'none',
        'color': '#333',
        'box-shadow': 'none'
      },
      ':host(:not([disabled])[light]:hover)': {
        'background': '#f2f2f2',
        'color': '#111'
      },
      ':host([small])': {
        'min-width': '75px',
        'font-size': '12px',
        'padding': '8px 10px'
      },
      ':host([disabled])': {
        'background': '#ddd',
        'color': '#666',
        'box-shadow': 'none'
      }
    }
  }

  static get template() {
    return html`
    <style>
      @keyframes spin {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
      
    .spinner {
      height: 21px;
      width: 21px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: white;
      margin: 0 auto;
      animation: spin 1s infinite linear
    }

    </style>
    <slot></slot>
    <span id="after"></span>
    `;
  }

  constructor() {
    super();
    this.addEventListener('click', (event) => {
      if (this.disabled) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    });
  }

  connectedCallback() {
    super.connectedCallback();
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'button');
    }
  }
}

export { Button as ButtonElement };