<mat-stepper [@.disabled]="true" #stepper>
  <mat-step
    [stepControl]="first"
    label="first"
    *ngIf="!loading && user?.onboarding"
  >
    <div class="col-12 col-lg-8">
      <div class="card my-3">
        <div class="card-header">
          <h2 class="mt-3">Tjekliste til åbning af konto hos Fundmarket</h2>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <p>
                  Vi er nødt til at vide mere om dig, før vi kan færdiggøre din
                  oprettelse, så du kan åbne en investeringskonto på Fundmarket.
                </p>

                <p class="fw-bold m-3">Du skal derfor have følgende klar:</p>

                <ol class="list-group list-group">
                  <li class="list-group-item">
                    <fa-icon [icon]="['fas', 'check-square']"></fa-icon>
                    Kopi af gyldigt billed-ID (pas eller kørekort, dog
                    accepterer vi ikke elektronisk kørekort)
                  </li>

                  <li class="list-group-item">
                    <fa-icon [icon]="['fas', 'check-square']"></fa-icon>
                    Kopi af sundhedskort for dit barn/dine børn, hvis du ønsker
                    at åbne en konto hertil, dog accepterer vi ikke elektronisk
                    sundhedskort
                  </li>
                </ol>
                <p class="fw-bold m-3">
                  Hvis du bor i udlandet, skal vi bruge følgende:
                </p>
                <ol class="list-group list-group">
                  <li class="list-group-item">
                    <fa-icon [icon]="['fas', 'check-square']"></fa-icon>
                    Hvis du er skattepligtig i et andet land end Danmark, skal
                    du oplyse dette, herunder dit udenlandske
                    skatteregistreringsnummer (TIN) samt tidspunkt for, hvornår
                    skattepligten træder/trådte i kraft.
                  </li>
                  <li class="list-group-item">
                    <fa-icon [icon]="['fas', 'check-square']"></fa-icon>
                    Dokumentation, som bekræfter din adresse (f.eks. kopi af
                    købsaftale på bolig, kontoudtog fra din udenlandske bank
                    eller udskrift fra myndighedsregister f.eks. skattemyndighed
                    eller forsikringsselskab, som bekræfter din adresse).
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-3" *ngIf="!loading">
      <div class="col-12 col-lg-8 text-end float-right">
        <button mat-button class="btn btn-primary" matStepperNext>Frem</button>
      </div>
    </div>
  </mat-step>
  <mat-step [stepControl]="profile" label="profile">
    <app-person-info
      [onboarding]="true"
      (goForward)="eventPI($event)"
      #pStep
    ></app-person-info>
    <div class="row my-3">
      <div class="col-6 text-start">
        <button mat-button class="btn btn-primary" matStepperPrevious>
          Tilbage
        </button>
      </div>
      <div class="col-6 col-lg-2 text-end float-right">
        <button
          mat-button
          class="btn btn-primary"
          [disabled]="!forwardPI"
          (click)="clickPstep()"
        >
          Frem
        </button>
      </div>
    </div>
  </mat-step>

  <mat-step [stepControl]="upload">
    <app-upload (goForward)="eventUID($event)"></app-upload>
    <div class="row my-3">
      <div class="col-6 text-start">
        <button mat-button class="btn btn-primary" matStepperPrevious>
          Tilbage
        </button>
      </div>
      <div class="col-6 col-lg-2 text-end float-right">
        <button
          mat-button
          class="btn btn-primary"
          [disabled]="!forwardUID"
          matStepperNext
        >
          Frem
        </button>
      </div>
    </div>
  </mat-step>

  <mat-step [stepControl]="kontovalg">
    <app-account-choice (goForward)="eventACC($event)"></app-account-choice>
    <div class="row my-3">
      <div class="col-6 text-start">
        <button mat-button class="btn btn-primary" matStepperPrevious>
          Tilbage
        </button>
      </div>
      <div class="col-6 col-lg-2 text-end float-right">
        <button
          mat-button
          class="btn btn-primary"
          [disabled]="!forwardACC"
          (click)="nextStep()"
        >
          Frem
        </button>
      </div>
    </div>
  </mat-step>
  <mat-step [stepControl]="questions">
    <app-aml (goForward)="eventQall($event)" #qStep></app-aml>
    <div class="row my-3">
      <div class="col-6 text-start">
        <button mat-button class="btn btn-primary" matStepperPrevious>
          Tilbage
        </button>
      </div>
      <div class="col-6 text-end float-right">
        <button
          mat-button
          class="btn btn-primary"
          [disabled]="!forwardQ"
          (click)="submitOnboarding()"
        >
          Frem
        </button>
      </div>
    </div>
  </mat-step>
  <mat-step [stepControl]="finale" [completed]="isCompleted">
    <app-onboarding-complete #ocStep></app-onboarding-complete>
    <div class="row my-3 ng-star-inserted" *ngIf="!loading">
      <div class="col-12 col-lg-8 text-center">
        <button mat-button class="btn btn-primary" (click)="logaf()">
          Log ud
        </button>
      </div>
    </div>
  </mat-step>
</mat-stepper>
<app-spinner [spinning]="loading"></app-spinner>
