export class Queue {
  constructor() {
    this.queue = [];
    this.isIdle = true;
  }

  add(toast) {
    this.queue.push(toast);
    if (this.isIdle) {
      this.next();
    }
  }

  next() {
    if (this.queue.length) {
      const toast = this.queue.shift();
      toast.serve();
      this.isIdle = false;
    } else {
      this.isIdle = true;
    }
  }
}