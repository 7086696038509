import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-error-reference-field',
    templateUrl: './error-reference-field.component.html',
    styleUrls: ['./error-reference-field.component.css'],
})
export class ErrorReferenceFieldComponent {
    @Input()
    referenceId: string;

    async onClickCopy() {
        await navigator.clipboard.writeText(this.referenceId || '');
    }
}
