import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Profile } from '@sharedmodels/profile.model';
import { RealOwner } from '@sharedmodels/realowner.model';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
  profile: Profile = <Profile>{};
  realowner: true;
  aml: true;
  personinfo: true;
  realOwners: RealOwner[];
  address1: string;
  selected;
  loading: boolean = false;
  transactionTabIndex: number = 0;

  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.getRealowners();

    this.route.data.subscribe((data) => {
      if (data?.realowner) {
        this.realowner = data?.realowner;
      } else if (data?.aml) {
        this.aml = data?.aml;
      } else if (data?.personinfo) {
        this.personinfo = data?.personinfo;
      }
    });
  }

  setTransactionTabIndex(e: any) {
    this.transactionTabIndex = e.index;
    localStorage.setItem('transactionTabIndex', e.index);
  }

  getRealowners() {
    this.loading = true;
    this.profileService.getRealowners().subscribe((realOwners: RealOwner[]) => {
      this.loading = false;
      this.realOwners = [...realOwners];

      if (this.realowner) {
        this.transactionTabIndex = 1;
      } else if (this.aml) {
        if (this.realOwners?.length > 0) {
          this.transactionTabIndex = 4;
        } else {
          this.transactionTabIndex = 3;
        }
      } else if (this.personinfo) {
        if (this.realOwners?.length > 0) {
          this.transactionTabIndex = 4;
        } else {
          this.transactionTabIndex = 3;
        }
      } else {
        this.transactionTabIndex =
          +localStorage.getItem('transactionTabIndex') || 0;
      }
    });
  }
}
