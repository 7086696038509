<div class="login-background"></div>
<app-prenavigation></app-prenavigation>
<div class="card">
    <div class="card-intro">
        <div class="card-intro-top">
            <h2 class="card-intro-header">Opret konto med MitID</h2>
            <p>Det er gratis at oprette en konto på Fundmarket. Du starter oprettelsen ved at udfylde felterne
                til højre. Processen tager nogle få minutter, og når vi har godkendt dig, kan du begynde at handle.
            </p>
        </div>
        <div class="card-intro-bottom">
            <app-help-note></app-help-note>
        </div>
    </div>
    <div class="card-form">
        <form [formGroup]="form">
            <div class="form-field">
                <label for="email" class="text-input-label">Email</label>
                <input id="email" type="email" class="text-input" formControlName="email" autocomplete="on" required
                    autofocus />
                <span *ngIf="form.controls.email.invalid && form.controls.email.dirty"
                    class="form-field-message">Ugyldig email</span>
            </div>

            <div class="form-field">
                <label for="fullname" class="text-input-label">Navn</label>
                <input id="fullname" type="text" class="text-input" formControlName="fullname" required />
            </div>

            <div class="form-field">
                <mat-radio-group id="registrationType" formControlName="registrationType" color="primary">
                    <mat-radio-button value="private">Privat</mat-radio-button>
                    <mat-radio-button value="business">Erhverv</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="form-field">
                <mat-checkbox id="acceptTerms" formControlName="acceptTerms" color="primary">
                    Jeg har læst og forstået
                    <a href="https://fundmarket.dk/handelsbetingelser/" target="_blank">Fundmarkets
                        handelsbetingelser.</a>
                </mat-checkbox>
                <span *ngIf="form.controls.acceptTerms.invalid" class="form-field-message">Handelsbetingelserne skal
                    accepteres, hvis du vil oprette dig på Fundmarket.</span>
            </div>

            <div class="form-field">
                <mat-checkbox id="acceptMailingList" formControlName="acceptMailingList" color="primary">
                    Jeg vil gerne
                    modtage nyheder fra Fundmarket via e-mail.
                    <p class="note">Du kan til enhver tid trække dit samtykke tilbage ved at benytte det tilhørende
                        link i bunden af e-mailen.</p>
                </mat-checkbox>
            </div>

            <div *ngIf="error" class="error">{{ error }}</div>

            <app-mitid-button (click)="onSubmitRegistration()" [disabled]="!form.valid || !form.value.acceptTerms"
                [loading]="mitidLoading"></app-mitid-button>
        </form>
    </div>
</div>