import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Credential } from '@sharedmodels/credential.model';
import { Resp } from '@sharedmodels/resp.model';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
})
export class UserInfoComponent implements OnInit {
  loading: boolean = true;
  credentials = <Credential>{};
  userinfo: FormGroup;
  info: string;
  error: string;
  res: any;
  hide: boolean = true;

  @Output() goForward = new EventEmitter<boolean>();
  @Input() onboarding?: boolean;

  constructor(private profileService: ProfileService,
    public cdf: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.info = '';
    this.error = '';
    this.userinfo = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(6),
        Validators.pattern(/[a-zæøå]/),
        Validators.pattern(/[A-ZÆØÅ]/),
        Validators.pattern(/\d/),
      ]),
      twophase: new FormControl(),
    });
    this.getCredentials();

    if (this.onboarding) {
      this.userinfo.statusChanges.subscribe((status) => {
        this.loading = false;
        this.onFormValidation(status);
      });
    }
  }

  onFormValidation(validity: string) {
    switch (validity) {
      case 'VALID':
        this.goForward.emit(true);
        break;
      case 'INVALID':
        this.goForward.emit(false);
        break;
      default:
        this.goForward.emit(false);
        break;
    }
  }

  getCredentials() {
    this.loading = true;
    this.profileService
      .getCredentials()
      .subscribe((credentials: Credential) => {
        this.credentials = credentials;
        this.userinfo.get('username').setValue(credentials.username);
        this.userinfo.controls.twophase?.setValue(true);
        this.loading = false;
        this.cdf.detectChanges();
      });
    this.loading = false;
  }

  async saveUserinfo(): Promise<void> {
    this.error = '';
    this.info = '';

    this.loading = true;
    const response = await this.profileService.saveUserCredentials({
      username: this.userinfo.value.username,
      password: this.userinfo.value.password,
    });
    this.loading = false;

    if (response.status === "OK") {
      this.info = "Brugernavn og adgangskode er blevet opdateret.";
    } else if (response.type === "InvalidRequest") {
      this.error = "Brugernavn og/eller adgangskode er ugyldige. Sørg for at overholde kravene, og prøv igen.";
    } else if (response.type === "UsernamePasswordMismatch") {
      this.error = "Brugernavn og adgangskode skal begge angives, hvis du vil ændre dem.";
    } else if (response.type === "UsernameExists") {
      this.error = "Brugernavnet er allerede i brug. Vælg et andet.";
    } else {
      this.error = "Der skete en fejl, som er blevet registreret i vores system, og vi arbejder på at løse den. Prøv igen senere."
    }

    this.getCredentials();
  }

  toggleHidePassword() {
    this.hide = !this.hide;
  }
}
