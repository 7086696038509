<div class="card m-3">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-5 col-sm-12 mt-3">
        <h2>Kontovalg</h2>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="form-inline">
      <div class="my-3 col-12 col-lg-10">
        <div class="card">
          <div class="card-body" *ngIf="custodies?.length > 0">
            <div class="mb-3 row">
              <h2 class="card-titel">Dine konti hos Fundmarket</h2>

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class=""></th>
                      <th class="">Konto</th>
                      <th >Kontonavn</th>
                      <th></th>
                      <th class="">Skattetype</th>
                      <th class="">Ejer</th>
                      <th class="">CPR/CVR/SE</th>
                      <th class="">Udbetaling</th>
                      <th class="">Særlige forhold</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let cus of custodies; let i = index">
                    <ng-container>
                      <tr>
                        <td
                          *ngIf="cus?.custodytype_code === 'PENS'"
                          class="w-auto align-middle"
                          (click)="expand(cus)"
                        >
                          <i *ngIf="!cus.expanded" class="fa fa-chevron-up"></i>
                          <i
                            *ngIf="cus.expanded"
                            class="fa fa-chevron-down"
                          ></i>
                        </td>
                        <td *ngIf="cus.custodytype_code !== 'PENS'"></td>
                        <td class="w-auto">{{ cus.custody_code }}</td>

                        <td class="span2">
                          <input
                            type="text"
                            class="form-control"
                            id="custody_name"
                            [(ngModel)]="cus.custody_name"
                            name="custody_name_{{ i }}"
                            maxlength="25"
                          />
                        </td>
                        <td class="">
                          <button
                            [disabled]="!cus.custody_name"
                            class="btn btn-primary"
                            id="button_{{ i }}"
                            (click)="changeCustodyName(cus); showNameInfo = i"
                          >
                            Gem
                          </button>
                        </td>

                        <td class="w-auto">{{ cus.custody_type }}</td>
                        <td class="w-auto">{{ cus.owner_name }}</td>
                        <td class="w-auto">{{ cus.publicid }}</td>
                        <td class="w-auto">{{ cus.outbankaccount }}</td>

                        <td class="w-auto">{{ cus.specialterms }}</td>
                      </tr>
                      <tr>
                        <td colspan="9" *ngIf="cus.expanded">
                          <div class="table-responsive overflow-hidden">
                            <form>
                              <table class="table">
                                <thead>
                                  <th
                                    colspan="3"
                                    *ngIf="cus.custodytype_code === 'PENS'"
                                    class="col-5"
                                  >
                                    Din investeringsrådgivers kontaktdetaljer
                                  </th>
                                  <th class="w-auto"></th>
                                  <th class="w-auto">Eksternt depot</th>
                                </thead>
                                <tbody>
                                  <tr class="align-middle bg-light">
                                    <td>
                                      <div class="form-group">
                                        <label for="contact_email">Email</label>
                                        <input
                                          type="email"
                                          class="col-10 form-control"
                                          id="contact_email"
                                          [(ngModel)]="cus.contact_email"
                                          name="contact_email{{ i }}"
                                          maxlength="25"
                                          placeholder="Skriv din email her"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div class="form-group">
                                        <label for="name">Navn</label>
                                        <input
                                          type="text"
                                          class="col-10 form-control"
                                          [(ngModel)]="cus.contact_name"
                                          name="contact_name{{ i }}"
                                          maxlength="25"
                                          id="contact_name"
                                          placeholder="Skriv dit navn her"
                                        />
                                      </div>
                                    </td>
                                    <td class="stick-bottom">
                                      <div class="form-group">
                                        <button
                                          type="button"
                                          [disabled]="
                                            !cus.contact_name ||
                                            !cus.contact_email
                                          "
                                          (click)="
                                            changeContactDetails(cus);
                                            showContactInfo = i
                                          "
                                          class="mt-3 btn btn-primary"
                                          id="button_{{ i }}"
                                        >
                                          Gem
                                        </button>
                                      </div>
                                    </td>
                                    <td
                                      class="w-auto text-success col-auto"
                                      *ngIf="info && showContactInfo === i"
                                      class=""
                                    >
                                      {{ info }}
                                    </td>
                                    <td
                                      class="w-auto text-danger col-auto"
                                      *ngIf="error && showContactInfo === i"
                                      class=""
                                    >
                                      {{ error }}
                                    </td>
                                    <td
                                      class="w-auto"
                                      *ngIf="
                                        (!error && showContactInfo !== i) ||
                                        (!info && showContactInfo !== i)
                                      "
                                    ></td>
                                    <td class="w-auto">{{ cus.externalid }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </form>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>

              <p *ngIf="custodies?.length === 0">
                Du har ingen konti hos Fundmarket endnu.
              </p>
            </div>
          </div>
          <div class="card" *ngIf="custodyuniverses?.length > 0">
            <div class="card-body">
              <h2 class="card-title">Opret nye konti</h2>

              <h3 class="card-title">
                Herunder er de konti du har mulighed for oprette:
              </h3>
              <div class="row">
                <div *ngIf="showMessage" class="col-sm-12 col-lg-3">
                  <p class="alert-info">Du kan ikke oprette flere konti.</p>
                  <p class="alert-info">
                    Kontakt Fundmarket A/S hvis det er en fejl.
                  </p>
                </div>
              </div>

              <div class="row">
                <ng-container
                  class=""
                  *ngFor="let cusun of custodyuniverses; let j = index"
                >
                  <div
                    class="col-lg-4 col-md-4 col-sm-4 col-12 mt-lg-3 mb-lg-3 mt-3"
                  >
                    <div class="card text-center card-wrapper">
                      <div class="card-header text-start">
                        <div class="row">
                          <div class="col-lg-2">
                            <i mat-card-avatar class="m-1 material-icons">{{
                              changeIcon(cusun)
                            }}</i>
                          </div>
                          <div class="col-lg-10">
                            <div class="row">
                              <div class="col-12">
                                {{ cusun.description }}
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                {{ cusun.explanation }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body text-start">
                        <p class="mt-1 card-text">
                          Her kan du oprette en ny konto.
                        </p>
                        <p
                          *ngIf="
                            cusun.models?.length > 0 &&
                            cusun.custodytype_id !== 5 &&
                            !checkCount(cusun)
                          "
                        >
                          Vælg i rådgivers modelportefølje herunder.
                        </p>
                        <div
                          *ngIf="info && showCreateNew === j"
                          class="text-success"
                        >
                          {{ info }}
                        </div>
                        <div
                          *ngIf="error && showCreateNew === j"
                          class="text-danger"
                        >
                          {{ error }}
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="row">
                          <div class="col-5">
                            <button
                              [disabled]="checkCount(cusun)"
                              (click)="
                                createCustodies(cusun); showCreateNew = j
                              "
                              class="btn btn-primary"
                            >
                              Opret ny
                            </button>
                          </div>
                          <div class="col-7">
                            <div
                              *ngIf="
                                cusun.models?.length > 0 &&
                                cusun.custodytype_id !== 5
                              "
                            >
                              <select
                                [disabled]="checkCount(cusun)"
                                class="form-select"
                                (change)="selectOption($event, cusun)"
                              >
                                <option selected>Vælg...</option>
                                <option
                                  [value]="model.id"
                                  *ngFor="let model of cusun.models"
                                >
                                  {{ model.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-spinner [spinning]="loading"></app-spinner>
