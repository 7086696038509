<div class="card m-3">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-5 col-sm-12 mt-3">
        <h2>Profiloplysninger</h2>
      </div>
    </div>
  </div>
  <div class="">
    <mat-tab-group
    dynamicHeight      
      animationDuration="0ms"
      [(selectedIndex)]="transactionTabIndex"
      (selectedTabChange)="setTransactionTabIndex($event)"
    >
      <mat-tab label="Personoplysninger">
        <app-person-info></app-person-info>
      </mat-tab>
      <mat-tab label="Brugeroplysninger">
        <app-user-info></app-user-info>
      </mat-tab>
      <mat-tab label="Upload">
        <app-upload></app-upload>
      </mat-tab>
      <mat-tab label="Spørgsmål vedr. hvidvask">
        <app-aml></app-aml>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
