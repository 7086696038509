import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Resp } from '@sharedmodels/resp.model';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { AuthService } from './auth.service';
import { AppConstants } from './constants/constants';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService extends ErrorHandler {
  fejl = <Resp>{};
  SERVER: string = 'SERVER';
  CLIENT: string = 'CLIENT';
  loading: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    public errorService: ErrorService
  ) {
    super();
  }

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      if (error?.status === 500) {
        this.handle500Error(error);
      } else if (error?.status === 404) {
        this.handle404Error(error);
      } else if (error?.status === 401) {
        this.handle401Error(error);
      }
    } else {
      if (error !== undefined) {
        this.handleOtherError(error);
      }
    }
    this.authService.logout();
    type Timer = ReturnType<typeof setTimeout>;
    const timer: Timer = setTimeout(() => {
      this.router.navigateByUrl('login');
    }, 10000);
  }

  private handle500Error = (error: HttpErrorResponse) => {
    this.logError(error);
    this.router.navigate(['/dberror']);
  };

  private handle401Error = (error: HttpErrorResponse) => {
    this.logError(error);
    this.router.navigate(['/401']);
  };

  private handle404Error = (error: HttpErrorResponse) => {
    this.logError(error);
    this.router.navigate(['/404']);
  };

  private handleOtherError = (error: any) => {
    this.logError(error);
    this.router.navigate(['/error']);
  };

  logError(error: any) {
    const date = new Date().toUTCString();

    if (error instanceof HttpErrorResponse) {
      const obj: Object = {
        Tidsstempel: date,
        Type: AppConstants.httpError,
        Message: error?.message,
        Status: (<HttpErrorResponse>error).status,
      };

      this.sendErrortoBackend(obj);
    } else if (error instanceof TypeError) {
      const obj: Object = {
        Tidsstempel: date,
        Type: AppConstants.typeError,
        Message: error?.message,
        Stack: error?.stack,
      };
      console.error(obj);
      this.sendErrortoBackend(obj);
    } else if (error instanceof Error) {
      const obj: Object = {
        Tidsstempel: date,
        Type: AppConstants.generalError,
        Message: error?.message,
        Stack: error?.stack,
      };
      console.error(obj);
      this.sendErrortoBackend(obj);
    } else if (error instanceof ErrorEvent) {
      const obj: Object = {
        Tidsstempel: date,
        Type: AppConstants.generalError,
        Message: error.message,
      };
      console.error(obj);
      this.sendErrortoBackend(obj);
    } else {
      const obj: Object = {
        Tidsstempel: date,
        Type: AppConstants.somethingHappened,
        Message: error?.message,
        Stack: error?.stack,
      };
      this.sendErrortoBackend(obj);
    }
    return EMPTY;
  }

  sendErrortoBackend(error: any) {
    this.errorService
      .logError(error)
      .subscribe({ error: (e) => console.error(e) });
  }
}
