import { Base, Properties, Attributes, ShadyTemplate } from 'cedk';
import { createStyle } from './create-style.js';

const Super = ShadyTemplate(
  Attributes(
    Properties(
      Base(HTMLElement)
    )
  )
);

const styles = new WeakMap();
const mounted = new WeakSet();
const defined = new WeakSet();

export class Component extends Super {
  static define(name) {
    if (defined.has(this)) return;
    customElements.define(name, this);
    defined.add(this);
  }

  static setup() {
    if (styles.has(this)) return;
    super.setup();
    if (this.style == null) return;
    const style = this.style;
    const element = createStyle(style);
    styles.set(this, element);
  }

  connectedCallback() {
    const constructor = this.constructor;
    if (!mounted.has(this) && constructor.style !== undefined) {
      if (this.shadowRoot === null) {
        this.attachShadow({ mode: 'open' });
      }
      const node = styles.get(constructor);
      const clone = node.cloneNode(true);
      this.shadowRoot.appendChild(clone);
      mounted.add(this);
    }
    super.connectedCallback();
  }

  disconnectedCallback() {
    if (super.connectedCallback) {
      super.connectedCallback();
    }
  }

  /**
   * Shorthand for emitting a custom event.
   * 
   * @param {string} type The type/name of the event.
   * @param {*} detail Any extra state to include in the event.
   * @param {CustomEventInit} options
   */
  emit(type, detail, options) {
    const defaults = {
      bubbles: true,
      cancelable: true,
      detail
    };
    const init = Object.assign(defaults, options);
    return this.dispatchEvent(new CustomEvent(type, init));
  }
}