import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found-component',
  templateUrl: './not-found-component.html'
})
export class NotFoundComponent {

  constructor() { }

  

}
