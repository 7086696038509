import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '@sharedmodels/user.model';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  id_token: string = '';
  state: string = '';
  callbackUrl: string = environment.apiUrl;
  host: string = environment.host;
  name: string = '';
  res: any;
  user: User = <User>{};
  loginError: string;
  isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());

  constructor(private http: HttpClient, private router: Router) {
    var _token = sessionStorage.getItem('_token');
    var _state = sessionStorage.getItem('_state');
  }

  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }

  private hasToken(): boolean {
    return !!sessionStorage.getItem('APIToken1');
  }

  getLogin() {
    return this.http
      .get(this.callbackUrl + '/login', {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((user: User) => {
          return user;
        })
      );
  }

  logout() {
    //logud af backend
    if (sessionStorage?.getItem('APIToken1')) {
      this.http
        .post(this.callbackUrl + '/login/logout', {}, {
          headers: new HttpHeaders({
            fundmarkettoken: sessionStorage?.getItem('APIToken1') as string,
            Accept: 'multipart/form-data',
          }),
        })
        .subscribe({ error: (e) => console.error(e) });
    }
    if (
      Object.keys(this.user)?.length > 0 &&
      this.user?.method?.toLowerCase() !== 'up' &&
      this.user?.method?.toLowerCase() !== 'ups'
    ) {
      //logud af mitid/nemid
      window.open(
        this.host +
        '/oauth2/logout' +
        '?post_logout_redirect_uri=' +
        environment.post_logout_redirect_uri,
        '_self'
      );
    }

    sessionStorage.clear();
    this.isLoginSubject.next(false);
  }

  ngOnDestroy() {
    this.isLoginSubject.unsubscribe();
  }
}
