import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { environment } from "src/environments/environment";
import { MitIDError } from "../mitid";

type Session = {
    id: number;
    token: string;
    onboarding: boolean;
    unanswered_questions: boolean;
}

type CallbackSuccessResponse = {
    status: 'OK';
    session: Session,
};

type CallbackErrorResponse = {
    status: 'ERROR';
    type: ChallengeErrorType;
    reference?: string;
};

type ChallengeErrorType =
    | "ChallengeNotFound"
    | "ChallengeExpired"
    | "CodeExchange"
    | "Validate"
    | "UserNotFound"
    | "AccountInactive"
    | "DatabaseError";

type CallbackResponse =
    | CallbackSuccessResponse
    | CallbackErrorResponse;

const formatError = (error: ChallengeErrorType, referenceId?: string) => {
    switch (error) {
        case "ChallengeNotFound":
            return "Der skete en fejl. Prøv at logge ind igen.";
        case "ChallengeExpired":
            return "Der er gået for lang tid, siden du begyndte dit login. Prøv at logge ind igen.";
        case "Validate":
            if (referenceId) {
                return "Der skete en fejl under login. "
                    + "Du er velkommen til at kontakte os, og oplyse referencekoden nedenfor, så vi kan undersøge problemet.";
            } else {
                return "Der skete en fejl under login. "
                    + "Prøv at logge ind igen, eller kontakt os for hjælp.";
            }
        case "UserNotFound":
            return "Vi kunne ikke finde din bruger på Fundmarket. Hvis du mener dette er en fejl, så er du velkommen til at kontakte os, så vi kan hjælpe dig.";
        case "AccountInactive":
            return "Det engagement, som din bruger tilhører, er blevet deaktiveret. Hvis du mener dette er en fejl, så er du velkommen til at kontakte os, så vi kan hjælpe dig.";
        case "CodeExchange":
        case "DatabaseError":
        default:
            if (referenceId) {
                return "Der skete en fejl. Prøv at logge ind igen, eller kontakt os, og oplys referencekoden nedenfor.";
            } else {
                return "Der skete en fejl. Prøv at oprette dig igen, eller kontakt os for hjælp.";
            }
    }
};

const needsSignupButton = (error: ChallengeErrorType) => {
    switch (error) {
        case "UserNotFound":
            return true;
        default:
            return false;
    }
};

@Component({
    selector: 'app-login-callback',
    templateUrl: './login-callback.component.html',
    styleUrls: ['./login-callback.component.css'],
})
export class LoginCallbackComponent implements OnInit {
    loading: boolean = false;
    error: string | null = null;
    referenceId: string | null = null;
    signupButton: boolean = false;

    constructor(
        private router: Router,
        private authService: AuthService,
    ) { }

    ngOnInit() {
        this.handleAuth();
    }

    private async handleAuth() {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
        const state = queryParams.get('state');

        this.loading = true;
        const response = await fetch(`${environment.apiUrl}/login/mitid/callback`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code,
                state,
            }),
        });
        this.loading = false;

        const text = await response.text();

        const json: CallbackResponse = JSON.parse(text);
        if (json.status !== "OK") {
            this.handleError(json);
            return;
        }

        const session = json.session;

        sessionStorage.setItem('APIToken1', session.token);
        this.authService.isLoginSubject.next(true);

        if (session.onboarding) {
            this.router.navigateByUrl('onboarding');
        } else if (session.unanswered_questions) {
            this.router.navigateByUrl('profil/aml');
        } else {
            this.router.navigateByUrl('dashboard');
        }
    }

    private handleError(error: CallbackErrorResponse) {
        this.error = formatError(error.type, error.reference);
        this.signupButton = needsSignupButton(error.type);
        if (this.error.includes('referencekode')) {
            this.referenceId = error.reference;
        }
    }
}
