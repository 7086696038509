<div class="card">
  <div class="card-header">
    <h2>Oplys kontonummer og dokumentation</h2>
  </div>
  <div class="card-body row">
    <h2>Udbetalende konto vedr. VSO - venligt opload dokumentation for konto.</h2>

    <div class="col-lg-6 col-sm-12">
      <form [formGroup]="vsoinfo" (ngSubmit)="createNewVSO()">
        <div class="row">
          <div class="form-group col-sm-12 col-lg-6">
            <label class="required" for="account_number"
              >Udbetalende konto</label
            >
            <input
              type="text"
              id="account_number"
              formControlName="account_number"
              class="form-control"
              placeholder="Kontonummer"
              required
            />
            <div
              *ngIf="
                vsoinfo.controls['account_number'].invalid &&
                (vsoinfo.controls['account_number'].dirty ||
                  vsoinfo.controls['account_number'].touched)
              "
              class="p-2 alert alert-danger"
            >
              Skriv dit kontonummer her.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <button
              [disabled]="!vsoinfo.valid"
              class="btn btn-primary mt-3 mb-3"
              type="submit"
            >
              Gem ny konto
            </button>
          </div>
          <div class="col-auto align-self-center">
            <div *ngIf="info" class="text-success mt-3">{{ info }}</div>
            <div *ngIf="error" class="text-danger mt-3">{{ error }}</div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-6 col-sm-12">
      <app-upload
        [vso]="true"
        (sendFile)="sendFile($event)"
      ></app-upload>
    </div>
  </div>
</div>
<app-spinner [spinning]=loading></app-spinner>
