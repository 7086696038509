<div class="card">
  <div class="card-header">
    <h2>Opret konto til dine børn</h2>
  </div>
  <div class="card-body">
    <p>
      For hver børnekonto der oprettes skal der uploades sundhedskort eller
      billedid.
    </p>
  </div>
  <div class="card-body row">
    <div class="col-sm-12 col-lg-6">
      <form [formGroup]="childinfo" (ngSubmit)="createNewChild()">
        <div class="row">
          <div class="form-group col-sm-12 col-lg-6">
            <label class="required" for="childname">Barnets navn</label>
            <input
              type="text"
              id="childname"
              formControlName="owner_name"
              class="form-control"
              placeholder="Dit barns navn"
              required
            />
            <div
              *ngIf="
                childinfo.controls['owner_name'].invalid &&
                (childinfo.controls['owner_name'].dirty ||
                  childinfo.controls['owner_name'].touched)
              "
              class="p-2 alert alert-danger"
            >
              Skriv dit barns navn her
            </div>
          </div>
          <div class="form-group col-sm-12 col-lg-6">
            <label for="accountname">Kontoens navn</label>
            <input
              type="text"
              id="accountname"
              formControlName="custody_name"
              class="form-control"
              placeholder="Kontoens navn"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12 col-lg-6">
            <label class="required" for="cpr">CPR nr. </label>
            <input
              type="text"
              formControlName="owner_cpr"
              class="form-control"
              id="cpr"
              placeholder="Dit barns cpr nr. "
              required
            />
            <div
              *ngIf="
                childinfo.controls['owner_cpr'].invalid &&
                (childinfo.controls['owner_cpr'].dirty ||
                  childinfo.controls['owner_cpr'].touched)
              "
              class="p-2 alert alert-danger"
            >
              Skriv dit barns CPR nr. her
            </div>
          </div>
          <div class="form-group col-sm-12 col-lg-6">
            <div *ngIf="custodyuniverse.models?.length">
              <label>Vælg investeringsrådgivers modelportefølje</label>
              <select class="form-select" (change)="selectOption($event)">
                <option selected>Vælg...</option>
                <option
                  [value]="model.id"
                  *ngFor="let model of custodyuniverse.models"
                >
                  {{ model.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-check mt-3">
          <label class="form-check-label" for="owner_sameaddress">
            Har barnet samme adresse som dig?
          </label>
          <input
            class="form-check-input"
            formControlName="owner_sameaddress"
            type="checkbox"
            value=""
            id="owner_sameaddress"
          />
        </div>
        <div *ngIf="childinfo.get('owner_sameaddress').value === false">
          <div class="row">
            <div class="col-sm-12 col-lg-6">
              <label class="required" for="adresse1">Adresse (1)</label>
              <input
                type="text"
                formControlName="owner_address1"
                class="form-control"
                id="adresse1"
                placeholder="Adresse"
              />

              <div
                *ngIf="
                  childinfo.controls['owner_address1'].invalid &&
                  (childinfo.controls['owner_address1'].dirty ||
                    childinfo.controls['owner_address1'].touched)
                "
                class="p-2 alert alert-danger"
              >
                Skriv dit barns adresse her
              </div>
            </div>
            <div class="col-sm-12 col-lg-6">
              <label for="adresse2">Adresse (2)</label>
              <input
                type="text"
                class="form-control"
                formControlName="owner_address2"
                id="adresse2"
                placeholder="Adresse"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-lg-6">
              <label class="required" for="postby">Postnummer & by</label>
              <input
                type="text"
                formControlName="owner_zipcity"
                class="form-control"
                id="postby"
                placeholder="Postnummer & by"
              />
              <div
                *ngIf="
                  childinfo.controls['owner_zipcity'].invalid &&
                  (childinfo.controls['owner_zipcity'].dirty ||
                    childinfo.controls['owner_zipcity'].touched)
                "
                class="p-2 alert alert-danger"
              >
                Skriv dit barns postnummer og by her
              </div>
            </div>
            <div class="col-sm-12 col-lg-6"></div>
          </div>
          <div class="mt-3">
            <div class="col-md-4 col-sm-11">
              <mat-form-field>
                <mat-label>Bopælsland</mat-label>
                <input
                  matInput
                  formControlName="boland"
                  [matAutocomplete]="matAutoBo"
                  required
                />
                <mat-autocomplete
                  #matAutoBo="matAutocomplete"
                  [displayWith]="getCountry"
                  (optionSelected)="getSelCountryName($event)"
                >
                  <mat-option
                    *ngFor="let country of bolandcount | async"
                    [value]="country"
                    [disabled]="country.highrisk"
                  >
                    {{ country.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error>Dit barns bopælsland skal udfyldes</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-11">
            <mat-form-field>
              <mat-label>Dit barns primære statsborgerskab</mat-label>
              <input
                matInput
                formControlName="pristat"
                [matAutocomplete]="matAutoPri"
                required
              />
              <mat-autocomplete
                #matAutoPri="matAutocomplete"
                [displayWith]="getCountry"
                (optionSelected)="getSelcitizen1($event)"
              >
                <mat-option
                  *ngFor="let country of pristatcount | async"
                  [value]="country"
                  [disabled]="country.highrisk"
                >
                  {{ country.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-error class="mb-3"
                >Dit barns primære statsborgerskab skal udfyldes</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-md-4 col-sm-11">
            <mat-form-field>
              <mat-label>Dit barns evt. sekundært statsborgerskab</mat-label>
              <input
                matInput
                formControlName="sekstat"
                [matAutocomplete]="matAutoSek"
              />
              <mat-autocomplete
                #matAutoSek="matAutocomplete"
                [displayWith]="getCountry"
                (optionSelected)="getSelcitizen2($event)"
              >
                <mat-option
                  *ngFor="let country of sekstatcount | async"
                  [value]="country"
                  [disabled]="country.highrisk"
                >
                  {{ country.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row">       
          <div class="col-auto">
            <button
              [disabled]="!childinfo.valid"
              class="btn btn-primary mt-3 mb-3"
              type="submit"
            >
              Gem ny konto
            </button>
          </div>
          <div class="col-auto align-self-center">
            <div *ngIf="info" class="text-success mt-3">{{ info }}</div>
            <div *ngIf="error" class="text-danger mt-3">{{ error }}</div>
          </div>
        </div>
        <p>
          Hvis du ønsker at oprette flere børnekonti skal du efter du har gemt
          vælge "opret ny" på ny.
        </p>
      </form>
    </div>
    <div class="col-lg-6 col-sm-12">
      <app-upload
        [child]="true"        
        (sendFile)="sendFile($event)"
      ></app-upload>
    </div>
  </div>
</div>
<app-spinner [spinning]=loading></app-spinner>
