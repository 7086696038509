import { Component } from '../../shared/component';
import {
  isControlElement,
  isCheckbox,
  isNativeRadio,
  isNativeMultiSelect,
  getSelectValues,
  validateElement
} from './utils.js';

const onKeyup = Symbol();
const onClick = Symbol();
const onValidate = Symbol();
const presubmit = Symbol();

/**
 * # <fm-form>
 * 
 * ## Properties
 * ### role
 * - **Type:** `string`
 * - **Default:** `form`
 * - **Attribute:** `true`
 * 
 * ### method
 * - **Type:** `string`
 * - **Default:** `post`
 * 
 * ### url
 * - **Type:** `string`
 * 
 * ### valid
 * - **Type:** `boolean`
 * - **Attribute:** `true`
 * 
 * ### validateOn
 * - **Type:** `string`
 * - **Default:** `focusout`
 * 
 * ### value
 * - **Type:** `object`
 * - **Read Only:** `true`
 * 
 */
export class Form extends Component {
  static define() {
    super.define('fm-form');
  }

  static get properties() {
    return {
      role: {
        type: String,
        default: () => 'form',
        reflectToAttribute: true
      },
      method: {
        type: String,
        default: () => 'post'
      },
      url: {
        type: String
      },
      valid: {
        type: Boolean,
        reflectToAttribute: true
      },
      validateOn: {
        type: String,
        default: () => 'focusout',
      },
      value: {
        type: Object
      }
    };
  }

  constructor() {
    super();
    this._elements = [];
    this[onClick] = this[onClick].bind(this);
    this[onKeyup] = this[onKeyup].bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('click', this[onClick]);
    this.addEventListener('keyup', this[onKeyup]);
    this.addEventListener(this.validateOn, this[onValidate]);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this[onClick]);
    this.removeEventListener('keyup', this[onKeyup]);
    this.removeEventListener(this.validateOn, this[onValidate]);
    super.disconnectedCallback();
  }

  /**
   * The JSON-serialized value of this
   * form element.
   * 
   */
  get value() {
    return this.serialize();
  }

  /**
   * An array of all form controls belonging
   * to this form element.
   * 
   */
  get elements() {
    return Array.from(this.querySelectorAll('*'))
      .filter(isControlElement);
  }

  /**
   * Return the value of the form as an object.
   * Each key is the name of a control, each value is the control's value.
   * 
   * The following cases are treated specially:
   * - A checkbox yields an array.
   * - Multiple checkboxes with the same name are collected in the same array.
   * - A multi-select yields an array of all the selected values.
   * 
   */
  serialize() {
    const value = {};
    for (const element of this.elements) {
      if (isCheckbox(element)) {
        if (element.checked) {
          value[element.name] = (value[element.name] || []).concat(element.value);
        }
      }
      else if (isNativeRadio(element)) {
        if (element.checked) {
          value[element.name] = element.value;
        }
      }
      else if (isNativeMultiSelect(element)) {
        value[element.name] = getSelectValues(element);
      }
      else {
        value[element.name] = element.value;
      }
    }
    return value;
  }

  /**
   * Validate all of the form's controls.
   * If a control is invalid, it will receive a `data-invalid` attribute.
   * If a control is valid, it will receive a `data-valid` attribute.
   */
  validate() {
    return this.elements.every(validateElement);
  }

  /**
   * @private
   */
  [onValidate](event) {
    const src = event.target;
    if (isControlElement(src)) {
      let valid;
      if (src.validity != null) {
        valid = src.validity.valid;
      } else {
        valid = src.valid;
      }
      if (valid) {
        src.setAttribute('data-valid', '');
        src.removeAttribute('data-invalid');
      } else {
        src.setAttribute('data-invalid', '');
        src.removeAttribute('data-valid');
      }
    }
  }

  [onKeyup](event) {
    if (event.key !== 'Enter') return;
    const nodeName = event.target.nodeName;
    if (nodeName.toLowerCase() === 'textarea') return;
    this[presubmit]();
  }

  [onClick](event) {
    const src = event.target;
    const type = src.getAttribute('type');
    if (type == null) return;
    if (type.toLowerCase() !== 'submit') return;
    this[presubmit]();
  }

  /**
   * @private
   */
  [presubmit]() {
    const allowed = this.emit('submit');
    if (!allowed) return;
    this.submit();
  }

  /**
   * Submit the form in its current state.
   * The form will first attempt to validate itself.
   * If the form is invalid, an `invalid` event is fired and the submission is cancelled.
   * 
   * The form will then serialize the controls into a JSON representation and
   * send the content to `this.url` through a `this.method`-request.
   * 
   * If the response throws an error, the form will catch it and emit
   * an `error` event with the error.
   * 
   * If the response is successful, the form will emit a `response`
   * event with the response.
   * 
   */
  async submit() {
    const valid = this.validate();
    if (!valid) {
      this.emit('invalid');
      return;
    }
    const method = this.method;
    const url = this.url;
    const value = this.serialize();
    if (method && url) {
      try {
        const response = await API[method](url, value);
        this.emit('response', response);
      } catch (error) {
        this.emit('error', error);
      }
    }
  }
}

export { Form as FormElement };