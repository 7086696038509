<div class="card m-3">
  <div class="card-header text-center">
    <h3 class="text-center pt-5">Der er desværre sket en fejl.</h3>
  </div>
  <div class="card-body text-center">
    <div class="m-2">
      <fa-icon [icon]="['fas', 'frown']" size="5x"></fa-icon>
    </div>
    <h3 class="text-center">Fejlen er logget hos os og vi kigger på den.</h3>
    <h3 class="text-center">Log venligst på igen. Vi beklager besværet.</h3>
  </div>
</div>
