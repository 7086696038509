import {  
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { Custody } from '@sharedmodels/custody.model';
import { Custodyuniverse } from '@sharedmodels/custodyuniverse.model';
import { Resp } from '@sharedmodels/resp.model';
import { User } from '@sharedmodels/user.model';
import { AuthService } from '../services/auth.service';
import { CustodyService } from '../services/custody.service';
import { NavigationService } from '../services/navigation.service';
import { ChildAccountChoiceComponent } from './child-account-choice/child-account-choice.component';
import { VsoAccountChoiceComponent } from './vso-account-choice/vso-account-choice.component';

@Component({
  selector: 'app-account-choice',
  templateUrl: './account-choice.component.html',
})
export class AccountChoiceComponent implements OnInit {
  custodyuniverses: Custodyuniverse[];
  custodyuniverse = <Custodyuniverse>{};
  custodies: Custody[];
  custody = <Custody>{};
  error: string;
  info: string;
  accountchoice: FormGroup;
  array: any;
  custody_name: string;
  showNameInfo: number;
  showContactInfo: number;
  showCreateNew: number;
  faSortDown = faSortDown;
  faSortUp = faSortUp;
  modelInfo: FormGroup;
  pensInfo: FormGroup;
  selected: number;
  vsoCount: number = 0;
  companyCount: number = 0;
  childCount: number = 0;
  privateCount: number = 0;
  showMessage: boolean = false;
  user = <User>{};
  @Output() goForward = new EventEmitter<boolean>();
  loading: boolean;

  constructor(
    private custodyService: CustodyService,
    public matDialog: MatDialog,
    private changeDet: ChangeDetectorRef,
    public authService: AuthService,
    private router: Router,
    public navigationService: NavigationService
  ) {
    this.info = '';
    this.error = '';
    this.showNameInfo = null;
    this.showContactInfo = null;
    this.showCreateNew = null;
  }
  ngOnInit(): void {
    this.loading = true;

    this.pensInfo = new FormGroup({
      contact_name: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
      ]),
      contact_email: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.email,
      ]),
    });
    this.getLogin();
    this.getCostodyUniverse();
    this.getCostody();
  }

  getLogin() {
    this.authService.getLogin().subscribe((user: User) => {
      this.user = user;
    });
  }

  resetErrorInfo() {
    this.info = '';
    this.error = '';
    this.showNameInfo = null;
    this.showContactInfo = null;
    this.showCreateNew = null;
  }

  getCostodyUniverse() {
    this.loading = true;
    this.custodyService
      .getCustodyUniverse()
      .subscribe((custodyuniverses: Custodyuniverse[]) => {
        this.loading = false;
        this.custodyuniverses = [...custodyuniverses];
        this.custodyuniverses?.forEach((cusun) => {
          this.showMessage = this.checkCount(cusun);
        });
      }),
      (error) => {
        this.loading = false;
        this.error = error;
      };
    this.loading = false;
  }

  getCostody() {
    this.getLogin();
    this.loading = true;
    this.custodyService.getCustodies().subscribe((custodies: Custody[]) => {
      this.custodies = [];
      this.custodies = [...custodies];
      if(this.custodies?.length > 0){
        this.goForward.emit(true);    
      }
      else{
        this.goForward.emit(false);    
      }
      this.companyCount = 0;
      this.vsoCount = 0;
      this.privateCount = 0;
      this.childCount = 0;
      this.custodies?.forEach((cus) => {
        if (cus?.custodytype_code?.toUpperCase() == 'SELSKAB') {
          ++this.companyCount;
        } else if (cus?.custodytype_code?.toUpperCase() == 'VSO') {
          ++this.vsoCount;
        } else if (cus?.custodytype_code?.toUpperCase() == 'FRI') {
          ++this.privateCount;
        } else if (cus?.custodytype_code?.toUpperCase() == 'BARN') {
          ++this.childCount;
        }

        var ex = JSON.parse(sessionStorage.getItem('ex'));
        if (ex && cus?.custodytype_code == 'PENS') {
          if (cus?.custody_id === ex.id) {
            cus.expanded = true;
          }
        }
        if (!cus?.expanded) {
          cus.expanded = false;
        }
      });
      this.loading = false;
    }),
      (error) => {
        this.loading = false;
        this.error = error;
      };
  }

  checkCount(cusun: Custodyuniverse) {
    if (cusun?.custodytype_id == 1) {
      if (this.companyCount >= 3) return true;
    } else if (cusun?.custodytype_id == 2) {
      if (this.vsoCount >= 5) return true;
    } else if (cusun?.custodytype_id == 3) {
      if (this.privateCount >= 5) return true;
    } else if (cusun?.custodytype_id == 5) {
      if (this.childCount >= 6) return true;
    }
    return false;
  }

  changeCustodyName(cus: Custody) {
    this.info = '';
    this.error = '';
    this.loading = true;
    const id = cus.custody_id;
    const name = cus.custody_name;

    if (id && name) {
      this.custodyService.changeCustodyName(id, name).subscribe((resp: any) => {
        this.loading = false;
        if ((resp.message = 'OK')) {
          this.info = 'Kontonavn er nu ændret.';
          this.getCostody();
          cus.custody_name = name;
        } else {
          this.error = 'Der er desværre sket en fejl.';
        }
      }),
        (error) => {
          this.loading = false;
          this.error = error;
        };
    } else {
      this.loading = false;
      this.error = 'Du skal udfylde nyt navn.';
    }
  }

  getArray(val: string) {
    return (this.accountchoice.get(val) as FormArray).controls;
  }

  changeContactDetails(cus) {
    this.loading = true;
    cus.animationState = true;
    const newName = cus.contact_name;
    const id = cus.custody_id;
    const newEmail = cus.contact_email;
    this.custodyService
      .changeContactName(id, newName)
      .subscribe((resp: Resp) => {      
        this.loading = false;
        if (resp?.status?.toUpperCase() === 'OK') {
          this.info = 'Dine oplysninger er nu ændret.';
          this.getCostody();
          this.changeDet.detectChanges();
        } else {
          this.error = 'Der er desværre sket en fejl.';
        }
      }),
      (error) => {
        this.loading = false;
        this.error = error;
      };
    this.custodyService
      .changeContactEmail(id, newEmail)
      .subscribe((resp: Resp) => {
        this.loading = false;
        if (resp?.status?.toUpperCase() === 'OK') {
          this.info = 'Dine oplysninger er nu ændret.';
          this.getCostody();
          this.changeDet.detectChanges();
        } else {
          this.error = 'Der er desværre sket en fejl.';
        }
      }),
      (error) => {
        this.loading = false;
        this.error = error;
      };
  }

  changeIcon(cusun) {
    if (cusun.custodytype_id == 3) {
      return 'savingsicon';
    } else if (cusun.custodytype_id == 5) {
      return 'child_care';
    } else if (cusun.custodytype_id == 2) {
      return 'factory';
    } else if (cusun.custodytype_id == 1) {
      return 'business';
    } else if (cusun.custodytype_id == 4) {
      return 'business';
    }
    return 'savingsicon';
  }

  createCustodies(cusun: Custodyuniverse) {
    this.info = '';
    this.error = '';
    this.loading = true;
    if (cusun.custodytype_id == 1) {
      //Selskab
      this.custody.custodytype_id = 1;
      this.custody.custody_name = '';
      this.custody.owner_cvr = cusun.owner_cvr;
      this.custody.model_id = cusun.model_id;
      this.createCustody(this.custody);
    } else if (cusun.custodytype_id == 2) {
      //Virksomhedsskatteordning
      this.custody.custodytype_id = 2;
      this.custody.custody_name = '';
      this.custody.owner_cvr = cusun.owner_cvr;
      this.custody.model_id = cusun.model_id;      
      if (this.custody.owner_cvr === '' || this.custody.owner_cvr === null) {
        this.loading = false;
        this.openModalVSO(cusun.model_id);
      } else {
        this.loading = false;      
        this.createCustody(this.custody);
      }
    } else if (cusun.custodytype_id == 3) {
      //Privat
      this.custody.custodytype_id = 3;
      this.custody.custody_name = '';
      this.custody.model_id = cusun.model_id;
      this.custody.owner_cvr = null;
      this.createCustody(this.custody);
    } else if (cusun.custodytype_id == 5) {
      //Børnekonto
      this.custody.custodytype_id = 5;
      this.custody.owner_cvr = null;
      this.openModalChild();
    }
  }

  createCustody(custody: Custody) {
    this.loading = true;
    this.custodyService.createCustodies(this.custody).subscribe((resp: any) => {
      this.loading = false;
      if (resp?.message == 'OK') {
        this.info =
          'Din konto er nu oprettet. Du kan give den et nyt kontonavn i kontooversigten ovenfor.';
        this.getCostody();
      } else {
        this.error = resp?.message || 'Der er desværre sket en fejl.';
      }
    }),
      (error) => {
        this.loading = false;
        this.error = error;
      };
    //this.loading = false;
  }

  openModalChild() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '100vw';
    dialogConfig.width = '80vw';
    const modalDialog = this.matDialog.open(
      ChildAccountChoiceComponent,
      dialogConfig
    );

    modalDialog.afterClosed().subscribe((res) => {
      this.info = res;
      this.getCostody();
    });
  }

  openModalVSO(model_id) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '100vw';
    dialogConfig.width = '80vw';
    const modalDialog = this.matDialog.open(
      VsoAccountChoiceComponent,
      dialogConfig
    );
    (<VsoAccountChoiceComponent>modalDialog.componentInstance).model_id =
      model_id;
    modalDialog.afterClosed().subscribe((res) => {
      this.loading = true;
      this.info = res;
      this.getCostody();
      this.loading = false;
    });
  }

  selectOption(event, cusun: Custodyuniverse) {
    if (event.target.value) {
      cusun.model_id = event.target.value;
    }
  }

  expand(cus: Custody) {
    let ex = { id: cus.custody_id, expanded: true };
    sessionStorage.setItem('ex', JSON.stringify(ex));
    cus.expanded = !cus.expanded;
  }

  forward() {
    this.router.navigate(['/aml']);
  }
  back() {
    this.navigationService.back();
  }
}
