import {
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ToastElement } from 'legacy/src/components/toast';
import { FormElement } from 'legacy/src/components/form';
import { ButtonElement } from 'legacy/src/components/button';
import { IconElement } from 'legacy/src/components/icon/icon';
import { IdleService } from './services/idle.service';
import { AuthService } from './services/auth.service';
import { User } from '@sharedmodels/user.model';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { NotificationService } from './services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  loading: boolean = false;
  update: number;
  user = <User>{};
  loggedIn: boolean = false;
  url: string = '/dashboard';

  constructor(
    private idleService: IdleService,
    private authService: AuthService,
    private ref: ChangeDetectorRef,
    private router: Router,
    media: MediaMatcher,
    public notificationService: NotificationService,
    public globalErrorHandlerService: GlobalErrorHandlerService
  ) {
    idleService.idle.subscribe((s) => {
      this.authService.logout();
      this.router.navigateByUrl('login');
    });

    ButtonElement.define();
    IconElement.define();
    ToastElement.define();
    FormElement.define();
  }

  ngOnInit() {
    this.authService.isLoggedIn().subscribe((data) => {
      this.loggedIn = data;
      if (this.loggedIn) {
        this.getLogin();
      } else {
        this.loggedIn = false;
        this.user = null;
      }
    });
  }

  getLogin() {
    this.authService.getLogin().subscribe((user: User) => {
      this.loading = false;
      this.user = user;
    });
  }

  public logout() {
    this.loading = true;
    this.authService.logout();
    this.loading = false;
    this.router.navigateByUrl('login');
  }
}
