<div class="card m-3">
  <div class="card-header text-center">
    <h3 class="text-center pt-5">Der er desværre sket en fejl.</h3>
  </div>
  <div class="card-body text-center">
    <div class="m-2">
      <fa-icon
        class="m-2"
        [icon]="['fas', 'triangle-exclamation']"
        size="5x"
      ></fa-icon>
    </div>
    <h3>Der er desværre ikke kontakt til vores servere i øjeblikket.</h3>
  </div>
</div>
