import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Country } from '@sharedmodels/country.model';
import { GlobalErrorHandlerService } from './global-error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  countriesUrl: string = environment.apiUrl;
 
  header: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, private globalErrorHandlerService: GlobalErrorHandlerService) {}

  getCountries() {  
    return this.http.get<Country[]>(this.countriesUrl + '/lov/countries', {
      headers: new HttpHeaders({
        fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
      }),
    }).pipe(      
      map((countries: Country[]) =>{         
        return countries;
      }),
      catchError((err) => {
        return throwError(() =>
          this.globalErrorHandlerService.handleError(err)
        );
      })
    )
  }   
} 
