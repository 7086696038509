import { Component } from '@angular/core';

@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
})
export class QaComponent {
  loading: boolean = false;

  constructor() {}
}
