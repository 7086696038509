import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  LOCALE_ID,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { DateRangeComponent } from './legacy-components/date-range/date-range.component';
import { MaterialModule } from './material.module';
import { DatePipe } from '@angular/common';
import { AuthService } from './services/auth.service';
import { FmLineChartComponent } from './legacy-components/fm-line-chart/fm-line-chart.component';
import { TableComponent } from './qa/table/table.component';
import { QaComponent } from './qa/qa.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faArrowsLeftRight,
  faFilePdf,
  faFrown,
  fas,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { faTriangleExclamation as faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faFaceFrown as faFaceFrown } from '@fortawesome/free-solid-svg-icons';
import { GeneralErrorComponent } from './errors/general-error-component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProfileComponent } from './profile/profile.component';
import { SpinnerModule } from './spinner/spinner.module';
import { faCircleCheck as faCircle } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import localeDaExtra from '@angular/common/locales/extra/da';
import { DbErrorComponent } from './errors/db-error-component';
import { OnboardingModule } from './onboarding/onboarding.module';
import { PersonInfoModule } from './profile/person-info/person-info.module';
import { UserInfoModule } from './profile/user-info/user-info.module';
import { RealownerModule } from './profile/realowner/realowner.module';
import { SessionExpiredComponent } from './errors/session-expired-componet';
import { AuthenticationGuard } from './authentication.guard';
import { AmlModule } from './aml/aml.module';
import { ProfileModule } from './profile/profile.module';
import { AccountChoiceModule } from './account-choice/account-choice.module';
import { RegistrationComponent } from './registration/registration.component';
import { RegistrationCallbackComponent } from './registration-callback/registration-callback.component';
import { ProgressDotsComponent } from './progress-dots/progress-dots.component';
import { ErrorReferenceFieldComponent } from './error-reference-field/error-reference-field.component';
import { MitIDButtonComponent } from './mitid-button/mitid-button.component';
import { HelpNoteComponent } from './help-note/help-note.component';
import { LoginCallbackComponent } from './login-callback/login-callback.component';
import { AppNavigationComponent } from './app-navigation/app-navigation.component';
import { AppPreNavigationComponent } from './app-prenavigation/app-prenavigation.component';

registerLocaleData(localeDa, 'da', localeDaExtra);
@NgModule({
  declarations: [
    AppComponent,
    QaComponent,
    LoginComponent,
    LoginCallbackComponent,
    RegistrationComponent,
    RegistrationCallbackComponent,
    DateRangeComponent,
    FmLineChartComponent,
    TableComponent,
    GeneralErrorComponent,
    DbErrorComponent,
    SessionExpiredComponent,
    ProgressDotsComponent,
    ErrorReferenceFieldComponent,
    MitIDButtonComponent,
    HelpNoteComponent,
    AppNavigationComponent,
    AppPreNavigationComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    NoopAnimationsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    FontAwesomeModule,
    SpinnerModule,
    NgbModule,
    AppRoutingModule,
  ],
  exports: [],
  providers: [
    DatePipe,
    AuthService,
    AuthenticationGuard,
    [{ provide: LOCALE_ID, useValue: 'da-DK' }],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(
      faSpinner,
      faFaceFrown,
      faFrown,
      faCircle,
      faTriangleExclamation,
      faFilePdf,
      faArrowsLeftRight
    );
  }
}
