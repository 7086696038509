<div class="container-menu" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="toolbar">
    <mat-toolbar-row>
      <button mat-icon-button (click)="this.sidenav?.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <a href="/" class="logo">
        <img
          class="logo"
          src="../../assets/images/fundmarket-logo.jpg"
          alt="Fundmarket logo"
        />
      </a>
      <div
        *ngIf="
          loggedIn &&
          !user?.onboarding &&
          !mobileQuery.matches &&
          user?.approved
        "
      >
        <notification-view></notification-view>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container
    class="sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0"
    #sidenavcontainer
  >
    <mat-sidenav
      autosize
      #sidenav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
      id="no-print"
    >
      <mat-nav-list>
        <div *ngIf="!user?.authenticated && !user?.onboarding && !loggedIn">
          <a href="/investment-products">
            <mat-list-item> Investeringsfonde </mat-list-item>
          </a>
          <mat-list-item routerLink="/qa">
            Ofte stillede spørgsmål
          </mat-list-item>
          <mat-list-item routerLink="/login"> Login </mat-list-item>
        </div>
        <div *ngIf="user?.authenticated && loggedIn && !user?.onboarding">
          <div *ngIf="!user.approved">
            <div class="logged-in-user-not-approved">{{ user.name }}</div>
            <div class="not-approved text-danger">Afventer godkendelse</div>
          </div>
          <div
            *ngIf="user.approved && !user?.onboarding"
            class="logged-in-user"
          >
            {{ user.name }}
          </div>

          <mat-list-item routerLink="dashboard"> Overblik </mat-list-item>
          <mat-list-item routerLink="investment-products">
            Investeringsfonde
          </mat-list-item>
          <mat-accordion>
            <mat-expansion-panel [class.mat-elevation-z0]="true" dense>
              <mat-expansion-panel-header class="font-justering">
                Investering
              </mat-expansion-panel-header>
              <mat-nav-list>
                <mat-list-item routerLink="investment-choice"
                  >Investeringsvalg</mat-list-item
                >
                <mat-list-item routerLink="sell">Salg</mat-list-item>
                <mat-list-item routerLink="account/rebalance"
                  >Rebalancering</mat-list-item
                >
                <mat-list-item routerLink="order-status"
                  >Ordrestatus</mat-list-item
                >
                <mat-list-item routerLink="/documents"
                  >FAIF-erklæringer</mat-list-item
                >
              </mat-nav-list>
            </mat-expansion-panel>

            <mat-expansion-panel [class.mat-elevation-z0]="true" dense>
              <mat-expansion-panel-header class="font-justering">
                Rapporter
              </mat-expansion-panel-header>
              <mat-nav-list>
                <mat-list-item routerLink="/return"
                  >Afkastrapport</mat-list-item
                >
                <mat-list-item routerLink="/account/taxreport"
                  >Skatterapport</mat-list-item
                >
                <mat-list-item routerLink="/statement"
                  >Kontoudtog</mat-list-item
                >
                <mat-list-item routerLink="/taxform">
                  Selvangivelsen
                </mat-list-item>
              </mat-nav-list>
            </mat-expansion-panel>

            <mat-expansion-panel [class.mat-elevation-z0]="true" dense>
              <mat-expansion-panel-header class="font-justering">
                Min profil
              </mat-expansion-panel-header>
              <mat-nav-list>
                <mat-list-item routerLink="/profil">Profil</mat-list-item>
                <mat-list-item routerLink="/account-choice"
                  >Kontovalg</mat-list-item
                >
              </mat-nav-list>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-list-item routerLink="/messages"> Beskeder </mat-list-item>
          <mat-list-item routerLink="/qa">
            Ofte stillede spørgsmål
          </mat-list-item>

          <mat-list-item (click)="logout()"> Log ud </mat-list-item>
        </div>
        <div *ngIf="user?.onboarding">
          <mat-nav-list>
            <mat-list-item (click)="logout()"> Log ud </mat-list-item>
          </mat-nav-list>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
