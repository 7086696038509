function entries(obj) {
  return Object.entries(obj);
}

function createRule(selector, declarations) {
  return selector + '{' +
    declarations.join(';') +
  '}'
}

// type Rule = {
//   [selector: String]: Declaration
// }
// type Declaration = {
//   [property: String]: Value
// }
// type Value = string;
export function createStyle(rules) {
  const style = document.createElement('style');
  for (const [selector, declaration] of entries(rules)) { // [selector, {}]
    const pairs = entries(declaration); // [[property, value], [property, value]]
    const strings = pairs.map(pair => pair.join(':')); // ['property:value', 'property:value']
    const rule = createRule(selector, strings); // selector{property:value;property:value}
    const node = document.createTextNode(rule); // "selector{property:value;property:value}"
    style.appendChild(node);
  }
  return style;
}