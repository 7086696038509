import { MediaMatcher } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { AuthService } from "../services/auth.service";
import { User } from "model-library";
import { Router } from "@angular/router";

@Component({
    selector: 'app-navigation',
    templateUrl: './app-navigation.component.html',
    styleUrls: ['./app-navigation.component.css'],
})
export class AppNavigationComponent implements OnInit, OnDestroy {
    public loggedIn: boolean = false;
    public user: User | null = null;
    public mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;
    @ViewChild('sidenav') sidenav: MatSidenav;

    constructor(
        private router: Router,
        private ref: ChangeDetectorRef,
        public media: MediaMatcher,
        private authService: AuthService,
    ) {
        this.mobileQuery = media.matchMedia('(max-width: 1024px)');
        this._mobileQueryListener = () => ref.detectChanges();
    }

    ngOnInit(): void {
        this.mobileQuery.addEventListener('change', this._mobileQueryListener);

        this.authService.isLoggedIn().subscribe((data) => {
            this.loggedIn = data;
            if (this.loggedIn) {
                this.getLogin();
            } else {
                this.loggedIn = false;
                this.user = null;
            }
        });
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    }

    @HostListener('window:beforeprint', ['$event'])
    beforeprint() {
        this.sidenav.close();
    }

    getLogin() {
        this.authService.getLogin().subscribe((user: User) => {
            this.user = user;
        });
    }

    public logout() {
        this.authService.logout();
        this.router.navigateByUrl('login');
    }
}
