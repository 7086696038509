import { ChangeDetectorRef, Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { User } from '@sharedmodels/user.model';
import { OnboardingCompleteService } from '../services/onboarding-complete.service';

@Component({
  selector: 'app-onboarding-complete',
  templateUrl: './onboarding-complete.component.html',
})
export class OnboardingCompleteComponent {
  user = <User>{};

  loading: boolean = false;
  error: string = '';

  constructor(
    private onboardingCompleteService: OnboardingCompleteService,
    public authService: AuthService,
    public cdf: ChangeDetectorRef
  ) {}
  async submitOnboarding() {
    this.loading = true;
    this.authService.getLogin().subscribe((user: User) => {
      this.user = user;

      if (
        !this.user?.has_uploaded ||
        !this.user?.has_custodies ||
        this.user?.has_questions
      ) {
        this.loading = false;
        this.error =
          'Du mangler at udfylde alle oplysninger for at færdiggøre din oprettelse.';

        return;
      }

      this.onboardingCompleteService.submitOnboarding().subscribe((res) => {
        this.loading = false;

        if (res.status.toUpperCase() === 'ERROR') {
          if (res.message) {
            this.error = res.message;
          } else {
            this.error = 'Der er desværre sket en fejl.';
          }
        }

        this.cdf.detectChanges();
      });
    });
  }
}
