<div class="my-3 col-12 col-lg-8">
  <div class="card">
    <div class="card-body">
      <h2 class="mt-3 card-title">Personoplysninger</h2>
      <form [formGroup]="personinfo" (ngSubmit)="saveInfo()">
        <div class="mb-lg-3 mb-md-3 mt-lg-3 mt-md-3 row">
          <div class="col-lg-4 col-md-6 col-sm-11">
            <label class="required" for="Navn">Navn</label>
            <input
              type="text"
              formControlName="name"
              class="form-control"
              id="navn"
              placeholder="Navn"
            />
            <div
              *ngIf="
                personinfo.controls['name'].invalid &&
                (personinfo.controls['name'].dirty ||
                  personinfo.controls['name'].touched)
              "
              class="p-2 alert alert alert-danger"
            >
              Skriv dit navn her
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-11">
            <label class="required" for="adresse1">Adresse (1)</label>
            <input
              type="text"
              formControlName="adresse1"
              class="form-control"
              id="adresse1"
              placeholder="Adresse"
            />

            <div
              *ngIf="
                personinfo.controls['adresse1'].invalid &&
                (personinfo.controls['adresse1'].dirty ||
                  personinfo.controls['adresse1'].touched)
              "
              class="p-2 alert alert-danger"
            >
              Skriv din adresse her
            </div>
          </div>
        </div>
        <div class="mb-lg-3 mb-md-3 mt-lg-3 mt-md-3 row">
          <div class="col-lg-4 col-md-6 col-sm-11">
            <label for="adresse2">Adresse (2)</label>
            <input
              type="text"
              formControlName="adresse2"
              class="form-control"
              id="adresse2"
              placeholder="Adresse"
            />
            <div
              *ngIf="
                personinfo.controls['adresse2'].invalid &&
                (personinfo.controls['adresse2'].dirty ||
                  personinfo.controls['adresse2'].touched)
              "
              class="p-2 alert alert-danger"
            >
              Skriv din adresse her
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-11">
            <label class="required" for="postby">Postnummer & by</label>
            <input
              type="text"
              formControlName="postby"
              class="form-control"
              id="postby"
              placeholder="Postnummer & by"
            />
            <div
              *ngIf="
                personinfo.controls['postby'].invalid &&
                (personinfo.controls['postby'].dirty ||
                  personinfo.controls['postby'].touched)
              "
              class="p-2 alert alert-danger"
            >
              Skriv postnummer og by her
            </div>
          </div>
        </div>

        <div class="mb-lg-3 mb-md-3 mt-lg-3 mt-md-3 row">
          <div class="col-lg-4 col-md-6 col-sm-11">
            <label class="required" for="telefon">Telefonnummer</label>
            <input
              type="text"
              formControlName="tel"
              class="form-control"
              id="telefon"
              placeholder="Telefonnummer"
            />
            <div
              *ngIf="
                personinfo.controls['tel'].invalid &&
                (personinfo.controls['tel'].dirty ||
                  personinfo.controls['tel'].touched)
              "
              class="p-2 alert alert-danger"
            >
              Skriv dit telefonummer her - med landekode forrest
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-11">
            <label class="required" for="email">Email addresse</label>
            <input
              type="email"
              formControlName="email"
              class="form-control"
              id="email"
              placeholder="Skriv din email"
            />
            <div
              *ngIf="
                personinfo.controls['email'].invalid &&
                (personinfo.controls['email'].dirty ||
                  personinfo.controls['email'].touched)
              "
              class="p-2 alert alert-danger"
            >
              Skriv din email her
            </div>
          </div>
        </div>
        <div class="mb-lg-3 mb-md-3 mt-lg-3 mt-md-3 row">
          <div class="col-lg-4 col-md-6 col-sm-11" *ngIf="!employee">
            <label for="taxnumber"
              >Tax Identification Number (hvis du bor i udlandet)</label
            >
            <input
              type="text"
              formControlName="taxnumber"
              class="form-control"
              id="taxnumber"
              placeholder="Tax Identification Number"
            />
            <div
              *ngIf="
                personinfo.controls['taxnumber'].invalid &&
                (personinfo.controls['taxnumber'].dirty ||
                  personinfo.controls['taxnumber'].touched)
              "
              class="p-2 alert alert-danger"
            >
              Skriv dit TIN nummer her
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-11">
            <div *ngIf="profile.cpr !== 0 && realowner">
              <label for="cpr">CPR nr.</label>
              <p>{{ profile.cpr }}</p>
            </div>

            <div *ngIf="(profile?.cpr === 0 && realowner) || employee">
              <label *ngIf="employee" for="cpr">CPR nr.</label>
              <label *ngIf="!employee" class="required" for="cpr"
                >CPR nr.</label
              >
              <input
                type="text"
                class="form-control"
                id="CPR"
                formControlName="cpr"
                placeholder="Cpr nr."
              />
              <div
                *ngIf="
                  personinfo.controls['cpr'].invalid &&
                  (personinfo.controls['cpr'].dirty ||
                    personinfo.controls['cpr'].touched)
                "
                class="p-2 alert alert-danger"
              >
                Dit CPR nr. kan kun indeholde tal og skal være på min 10 cifre.
              </div>
            </div>
          </div>
        </div>
        <div class="mb-lg-3 mb-md-3 mt-lg-3 mt-md-3 row">
          <div class="col-lg-4 col-md-6 col-sm-11">
            <label for="c_name" *ngIf="employee">Bopælsland</label>
            <label for="c_name" *ngIf="!employee" class="required"
              >Bopælsland</label
            >
            <ng-autocomplete
              id="country_name"
              placeholder="Bopælsland"
              type="text"
              formControlName="country_name"
              [data]="countries"
              [notFoundTemplate]="notFoundTemplate"
              [itemTemplate]="itemTemplate"
              [searchKeyword]="keyword"
              (selected)="selectCountry_name($event)"
              (inputCleared)="clearCountry_name()"
              [customFilter]="customFilter"
            >
            </ng-autocomplete>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-11">
            <label for="c1_name" *ngIf="employee"
              >Primært statsborgerskab</label
            >
            <label for="c1_name" *ngIf="!employee" class="required"
              >Primært statsborgerskab</label
            >
            <ng-autocomplete
              id="citizen1_name"
              placeholder="Primært statsborgerskab"
              type="text"
              formControlName="citizen1_name"
              [data]="countries"
              [notFoundTemplate]="notFoundTemplate"
              [itemTemplate]="itemTemplate"
              [searchKeyword]="keyword"
              (selected)="selectCitizen1($event)"
              (inputCleared)="clearCitizen1()"
              [customFilter]="customFilter"
            >
            </ng-autocomplete>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-11">
            <label for="citizen2_name" class=""
              >Evt. sekundært statsborgerskab</label
            >
            <ng-autocomplete
              id="citizen2_name"
              placeholder="Evt. sekundært statsborgerskab"
              type="text"
              formControlName="citizen2_name"
              [data]="countries"
              [notFoundTemplate]="notFoundTemplate"
              [itemTemplate]="itemTemplate"
              [searchKeyword]="keyword"
              (selected)="selectCitizen2($event)"
              (inputCleared)="clearCitizen2()"
              [customFilter]="customFilter"
            >
            </ng-autocomplete>
            <div
              *ngIf="
                personinfo.controls['citizen2_name'].invalid &&
                (personinfo.controls['citizen2_name'].dirty ||
                  personinfo.controls['citizen2_name'].touched)
              "
              class="p-2 alert alert-danger"
            >
              Skriv dit sekundære statsborgerkab.
            </div>
          </div>
        </div>
        <div class="mb-lg-3 mb-md-3 mt-lg-3 mt-md-3 row">
          <div class="col-auto" *ngIf="!onboarding">
            <button
              (click)="loading = true"
              [disabled]="!personinfo.valid"
              type="submit"
              id="button"
              class="btn btn-primary ml-3"
            >
              <fa-icon
                [icon]="['fas', 'spinner']"
                [hidden]=!loading
                [spin]=loading
              ></fa-icon
              >Gem
            </button>
          </div>
          <div class="col-auto">
            <div class="text-success m-3">{{ info }}</div>
            <div class="text-danger m-3">{{ error }}</div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-spinner [spinning]=loading></app-spinner>
<ng-template #notFoundTemplate let-notFound>
  <div [innerHTML]="notFound"></div>
</ng-template>
<ng-template #itemTemplate let-item>
  <a [innerHTML]="item.name"></a>
</ng-template>
