import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { environment } from "src/environments/environment";

type Session = {
    id: number;
    token: string;
    onboarding: boolean;
}

type CallbackSuccessResponse = {
    status: 'OK';
    session: Session,
};

type CallbackErrorResponse = {
    status: 'ERROR';
    type: ChallengeErrorType;
    reference?: string;
};

type ChallengeErrorType =
    | "ChallengeNotFound"
    | "ChallengeExpired"
    | "RegistrationNotFound"
    | "CodeExchange"
    | "Validate"
    | "UserAlreadyExists"
    | "UnknownCountry"
    | "AccountInactive"
    | "DatabaseError";

type CallbackResponse =
    | CallbackSuccessResponse
    | CallbackErrorResponse;

const formatError = (error: ChallengeErrorType, referenceId?: string) => {
    switch (error) {
        case "ChallengeNotFound":
        case "RegistrationNotFound":
            return "Den forudgående oprettelse blev ikke fundet. Prøv at oprette dig igen.";
        case "ChallengeExpired":
            return "Der er gået for lang tid, siden du startede oprettelsen. Prøv at oprette dig igen.";
        case "Validate":
        case "UnknownCountry":
            if (referenceId) {
                return "Der skete en fejl under oprettelsen. Du er velkommen til at kontakte os, og oplyse referencekoden nedenfor.";
            } else {
                return "Der skete en fejl under oprettelsen. Du er velkommen til at kontakte os, så vi kan hjælpe dig.";
            }
        case "UserAlreadyExists":
            return "Du er allerede oprettet på Fundmarket. Gå til login-siden for at logge ind.";
        case "AccountInactive":
            return "Det engagement, som du er tilknyttet, er deaktiveret. Hvis du mener dette er en fejl, så er du velkommen til at kontakte os, så vi kan hjælpe dig.";
        case "CodeExchange":
        case "DatabaseError":
        default:
            if (referenceId) {
                return "Der skete en fejl under oprettelsen. Prøv at oprette dig igen, eller kontakt os, og oplys referencekoden nedenfor.";
            } else {
                return "Der skete en fejl under oprettelsen. Prøv at oprette dig igen, eller kontakt os for hjælp.";
            }
    }
};

const needsLoginButton = (error: ChallengeErrorType) => {
    return error === "UserAlreadyExists";
};

@Component({
    selector: 'app-registration-callback',
    templateUrl: './registration-callback.component.html',
    styleUrls: ['./registration-callback.component.css'],
})
export class RegistrationCallbackComponent implements OnInit {
    loading: boolean = false;
    error: string | null = null;
    referenceId: string | null = null;
    loginButton: boolean = false;

    constructor(
        private router: Router,
        private authService: AuthService,
    ) { }

    ngOnInit() {
        this.handleAuth();
    }

    private async handleAuth() {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
        const state = queryParams.get('state');

        this.loading = true;
        const response = await fetch(`${environment.apiUrl}/registration/mitid/callback`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code,
                state,
            }),
        });
        this.loading = false;

        const json: CallbackResponse = await response.json();
        if (json.status !== "OK") {
            this.handleErrorResponse(json);
            return;
        }

        const session = json.session;

        sessionStorage.setItem('APIToken1', session.token);
        this.authService.isLoginSubject.next(true);
        if (session.onboarding) {
            this.router.navigateByUrl('onboarding');
        } else {
            this.router.navigateByUrl('dashboard');
        }
    }

    private handleErrorResponse(json: CallbackErrorResponse) {
        this.error = formatError(json.type, json.reference);
        this.loginButton = needsLoginButton(json.type);
        if (this.error.includes("referencekode")) {
            this.referenceId = json.reference;
        }
    }
}
