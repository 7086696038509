<div class="card m-3">
  <div class="card-header">
    <h2>Spørgsmål vedr. hvidvask og investering</h2>
  </div>
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col" class="col-1">Dato</th>
          <th scope="col" class="col-1">Stillet til</th>
          <th scope="col" class="col-6">Spørgsmål</th>
          <th scope="col" class="col-2">Svar</th>
          <th scope="col" class="col-1">Status</th>
          <th scope="col" class="col-1">Besvaret af</th>
        </tr>
      </thead>
      <tbody *ngFor="let mq of meta_questions">
        <tr class="border type">
          <td class="text-white text-center" colspan="6">
            {{ mq.description }}
          </td>
        </tr>

        <tr *ngFor="let q of mq.questions">
          <td class="col-1">
            <div *ngIf="q?.answers?.length > 0">
              {{ q?.answers[0]?.answer_date }}
            </div>
          </td>
          <td class="col-1">{{ q.user_name }}</td>
          <td class="" [innerHTML]="q.question"></td>
          <td class="">
            <div class="form-check">
              <div *ngFor="let e of q.empty_answer">
                <ng-container
                  *ngIf="q?.answers?.length > 0; then template1; else template2"
                ></ng-container>

                <ng-template #template1>
                  <input
                    class="form-check-input"
                    id="{{ e.answer_id }}"
                    [value]="e.answer"
                    name="radio{{ q.ask_id }}"
                    type="radio"
                    (click)="toggle($event, q)"
                    (change)="saveAnswer($event, q)"
                    [checked]="q?.answers[0]?.answer === e.answer"
                  />               
                </ng-template>

                <ng-template #template2>
                  <input
                    class="form-check-input"
                    id="{{ e.answer_id }}"
                    [value]="e.answer"
                    name="radio{{ q.ask_id }}"
                    type="radio"
                    (click)="toggle($event, q)"
                    (change)="saveAnswer($event, q)"
                  />
                </ng-template>
                <label class="form-check-label" for="{{ e.answer_id }}">
                  {{ e.answer }}
                </label>
                <div *ngIf="e.other_yn === 'Y' && q.showOther">
                  <form (submit)="saveOther(q)" class="form-inline">
                    <div class="row">
                      <div class="form-group row">
                        <div class="col-md-8">
                          <ng-container
                            *ngIf="
                              q?.answers?.length > 0;
                              then template3;
                              else template4
                            "
                          ></ng-container>
                          <ng-template #template3>
                            <input
                              id="id-{{ q?.ask_id }}"
                              maxlength="20"
                              placeholder="Indtast her"
                              type="text"
                              class="form-control"
                              [value]="q?.answers[0]?.answer_other"
                            />
                          </ng-template>
                          <ng-template #template4>
                            <input
                              id="id-{{ q?.ask_id }}"
                              maxlength="20"
                              placeholder="Indtast her"
                              type="text"
                              class="form-control"
                              [value]=""
                            />
                          </ng-template>
                        </div>

                        <div class="col-md-4">
                          <button
                            (click)="q.isLoading = true"
                            type="submit"
                            id="{{ q.ask_id }}button"
                            class="btn btn-primary ml-3"
                          >
                            <fa-icon
                              [icon]="['fas', 'spinner']"
                              [hidden]="!q.isLoading"
                              [spin]="q.isLoading"
                            ></fa-icon
                            >Gem
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </td>
          <td class="">
            <div *ngIf="q.answers?.length > 0">
              <button
                type="button"
                style="pointer-events: none"
                class="btn btn-success"
              >
                Ok
              </button>
            </div>
            <div *ngIf="q.answers?.length === null">
              <button
                type="button"
                style="pointer-events: none"
                class="btn btn-warning"
              >
                Ikke besvaret
              </button>
            </div>
          </td>
          <td class="">
            <div *ngIf="q.answers?.length > 0">
              {{ q?.answers[0]?.answered_by }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-spinner [spinning]=loading></app-spinner>
