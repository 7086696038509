import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs/internal/operators/map';
import { startWith } from 'rxjs/internal/operators/startWith';
import { Country } from '@sharedmodels/country.model';
import { Custody } from '@sharedmodels/custody.model';
import { Custodyuniverse } from '@sharedmodels/custodyuniverse.model';
import { Resp } from '@sharedmodels/resp.model';
import { CountriesService } from 'src/app/services/countries.service';
import { CustodyService } from 'src/app/services/custody.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-child-account-choice',
  templateUrl: './child-account-choice.component.html',
})
export class ChildAccountChoiceComponent implements OnInit {
  showChildInfo: boolean = false;
  childinfo: FormGroup;
  custodyuniverses: Custodyuniverse[];
  custodyuniverse = <Custodyuniverse>{};
  custodies: Custody[];
  custody = <Custody>{};
  countries = new Array<Country>();
  bolandcount = <any>[];
  pristatcount = <any>[];
  sekstatcount = <any>[];
  loading: boolean = false;
  info: string;
  error: string;

  constructor(
    private formBuilder: FormBuilder,
    private countriesService: CountriesService,
    private dialogRef: MatDialogRef<ChildAccountChoiceComponent>,
    public custodyService: CustodyService,
    public sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.custody.file = null;
    this.getCountries();
    this.getCostodyUniverse();
    this.childinfo = this.formBuilder.group({
      owner_name: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
        ],
      ],
      custody_name: ['', [Validators.minLength(3), Validators.maxLength(20)]],
      boland: ['', Validators.required],
      sekstat: [''],
      pristat: ['', Validators.required],
      owner_sameaddress: [false],
      owner_address1: ['', [Validators.minLength(3), Validators.maxLength(20)]],
      owner_address2: ['', [Validators.minLength(3), Validators.maxLength(20)]],
      owner_zipcity: ['', [Validators.minLength(3), Validators.maxLength(20)]],
      owner_cpr: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
    });

    this.bolandcount = this.childinfo.get('boland').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((value) => this._filter(value))
    );

    this.pristatcount = this.childinfo.get('pristat').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((value) => this._filter(value))
    );

    this.sekstatcount = this.childinfo.get('sekstat').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((value) => this._filter(value))
    );

    this.childinfo.get('owner_sameaddress').valueChanges.subscribe((val) => {
      if (!val) {
        this.childinfo.get('boland').setValidators(Validators.required);
        this.childinfo.get('owner_address1').setValidators(Validators.required);
        this.childinfo.get('owner_zipcity').setValidators(Validators.required);
      }
      if (val) {
        this.childinfo.get('boland').setValidators(null);
        this.childinfo.get('owner_address1').setValidators(null);
        this.childinfo.get('owner_zipcity').setValidators(null);
        this.childinfo.get('pristat').setValidators(Validators.required);
      }
      this.childinfo.get('pristat').updateValueAndValidity();
      this.childinfo.get('boland').updateValueAndValidity();
      this.childinfo.get('owner_address1').updateValueAndValidity();
      this.childinfo.get('owner_zipcity').updateValueAndValidity();
    });
  }
  private _filter(value: string) {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.countries.filter((country) =>
        country.name.toLowerCase().includes(filterValue)
      );
    }
    return null;
  }

  getSelCountryName(e: MatAutocompleteSelectedEvent) {
    const country: Country = e.option.value;
    this.custody.owner_country_id = country.id;
  }

  getSelcitizen1(e: MatAutocompleteSelectedEvent) {
    const country: Country = e.option.value;
    this.custody.owner_citizen1_id = country.id;
  }

  getSelcitizen2(e: MatAutocompleteSelectedEvent) {
    const country: Country = e.option.value;
    this.custody.owner_citizen2_id = country.id;
  }

  getCountry(country?: Country): string | undefined {
    return country ? country.name : undefined;
  }

  getCountries() {
    this.countriesService.getCountries().subscribe((countries: Country[]) => {
      this.countries = [...countries];
    });
  }

  createNewChild() {
    this.info = '';
    this.error = '';
    this.custody.custody_name = this.childinfo.get('custody_name').value;
    this.custody.owner_name = this.childinfo.get('owner_name').value;
    this.custody.owner_sameaddress =
      this.childinfo.get('owner_sameaddress').value;
    this.custody.owner_address1 = this.childinfo.get('owner_address1').value;
    this.custody.owner_address2 = this.childinfo.get('owner_address2').value;
    this.custody.owner_zipcity = this.childinfo.get('owner_zipcity').value;
    //this.custody.owner_country_id = this.childinfo.get('boland').value;

    this.custody.owner_cpr = this.childinfo.get('owner_cpr').value;

    if (!this.custody.file) {
      this.info = 'Du mangler at uploade en fil';
      return;
    }
    this.createCustody(this.custody);
    //this.dialogRef.close();
  }

  selectOption(event) {
    if (event.target.value) {
      this.custody.model_id = event.target.value;
    }
  }

  public sendFile(file: any) {
    this.custody.file = file;
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj)?.length === 0;
  }

  getCostodyUniverse() {
    this.custodyService
      .getCustodyUniverse()
      .subscribe((custodyuniverses: Custodyuniverse[]) => {
        this.custodyuniverses = [...custodyuniverses];
        this.custodyuniverses?.forEach((cusun) => {
          if (cusun.custodytype_id == 5) {
            this.custodyuniverse = cusun;
          }
        });
      }),
      (error) => {
        this.loading = false;
        this.error = error;
      };
    this.loading = false;
  }

  createCustody(custody: Custody) {
    this.loading = true;
    this.custody.custodytype_id = 5;
    this.custody.owner_cvr = null;
    this.custodyService
      .createCustodies(this.custody)
      .subscribe((resp: Resp) => {
        this.loading = false;
        if (resp.status.toUpperCase() === 'OK') {        
          this.info = 'Din børnekonto er nu oprettet.';
          this.dialogRef.close(this.info);
        } else {
          this.error = resp.message || 'Der er desværre sket en fejl.';
        }
      }),
      (error) => {
        this.loading = false;
        this.error = error;
      };
    this.loading = false;
  }  
}
