<div class="login-background"></div>
<app-prenavigation></app-prenavigation>
<div class="card">
    <app-progress-dots *ngIf="loading"></app-progress-dots>
    <div *ngIf="error" class="error">{{ error }}</div>
    <app-error-reference-field *ngIf="referenceId" [referenceId]="referenceId"></app-error-reference-field>
    <a *ngIf="!loading && loginButton" href="/login">Gå til login</a>
    <a *ngIf="!loading && !loginButton" href="/oprettelse">Tilbage til oprettelse</a>

    <div class="help-note">
        <app-help-note></app-help-note>
    </div>
</div>