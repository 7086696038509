import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Fund } from '@sharedmodels/funds/fund.model';
import { InvestmentProductService } from 'src/app/services/investment-product.service';
import { SafeUrl } from '@angular/platform-browser';
import { BaseChartDirective } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { Doc } from '@sharedmodels/funds/doc.model';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-investment-product',
  templateUrl: './investment-product.component.html',
})
export class InvestmentProductComponent implements OnInit {
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
  portfolio_id: string;
  loading: boolean;
  fund = <Fund>{};
  logoPath: SafeUrl;
  webUrl: string = environment.webUrl;
  salesdate: any;
  price_method_help_text: string = '';
  public comGraphLabels = [];
  public comGraphshares = [];
  selDoc = <Doc>{};

  info: string;
  error: string;

  constructor(
    private route: ActivatedRoute,
    private investmentProductService: InvestmentProductService,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.selDoc = null;

    this.portfolio_id = this.route.snapshot.paramMap.get('portfolio_id');
    this.getInvestmentProductInfo(this.portfolio_id);
  }

  reloadChart() {
    this.charts?.forEach((child) => {
      child.chart.update();
      child.chart.resetZoom();
    });
  }

  getLogo(fund) {
    return this.webUrl + '/' + fund?.manager_logo;
  }

  getInvestmentProductInfo(portfolio_id) {
    this.loading = true;
    this.investmentProductService
      .getInvestmentProductInfo(portfolio_id)
      .subscribe((fund: Fund) => {
        this.loading = false;
        this.fund = fund;

        this.fund.top10 = this.fund?.top10.sort((a, b) => {
          if (a?.pct < b?.pct) {
            return 1;
          }
          if (a?.pct > b?.pct) {
            return -1;
          }
          return 0;
        });

        this.fund.composition = this.fund?.composition.sort((a, b) => {
          if (a?.share < b?.share) {
            return 1;
          }
          if (a?.share > b?.share) {
            return -1;
          }
          return 0;
        });
      });
  }

  selectOption() {
    window.open(this.selDoc?.link, '_blank');
  }

  pricemethod(fund: Fund) {
    switch (fund.price_method) {
      case 'Modificeret enkeltpris':
        return (this.price_method_help_text =
          'Efter den modificerede enkeltprismetode skal investeringsforeningen (UCITS) fastsætte et eller flere tidspunkter for opgørelsen af værdien af andele i en afdeling eller andelsklasse. Til afregning af anmodning om emission og indløsning af andele, skal investeringsforeningen (UCITS) fastsætte emissionsprisen og indløsningsprisen med udgangspunkt i indre værdi på det førstkommende opgørelsestidspunkt. Hvis en afdeling i investeringsforeningen (UCITS) benytter modificeret enkeltprismetode, skal størrelsen på det antal dele, som udløser en justering af den indre værdi, offentliggøres. Ligeledes skal størrelsen på det gebyr offentliggøres, som den indre værdi justeres med. Offentliggørelsen sker i salgsmaterialet.');
      case 'Enkelt pris':
        return (this.price_method_help_text =
          'Enkeltprismetoden betyder, at investeringsforeningen (UCITS) skal fastsætte et eller flere tidspunkter for opgørelsen af værdien af andele i en afdeling eller andelsklasse. Til afregning af anmodninger om emission og indløsning af andele skal investeringsforeningen (UCITS) fastsætte emissionsprisen og indløsningsprisen til indre værdi på det førstkommende opgørelsestidspunkt.');

      case 'Dobbelt pris':
        return (this.price_method_help_text =
          'Dobbeltprismetoden betyder, at investeringsforeningen (UCITS) skal fastsætte emissionsprisen/indløsningsprisen til den indre værdi opgjort på emissions/indløsningstidspunktet, med tillæg/fradrag af et beløb til dækning af udgifter ved køb af finansielle instrumenter og til nødvendige omkostninger ved emissionen/indløsningen.');

      default:
        return (this.price_method_help_text = '');
    }
  }
}
