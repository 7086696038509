import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { Fund } from '@sharedmodels/funds/fund.model';
import { Umbrella } from '@sharedmodels/funds/umbrella.model';
import { throwError } from 'rxjs/internal/observable/throwError';
@Injectable({
  providedIn: 'root',
})
export class InvestmentProductService {
  investmentProductUrl: string = environment.fundUrl;
  bond_funds: Fund[];
  eq_funds: Fund[];
  mixed_funds: Fund[];

  constructor(
    private http: HttpClient,
    public globalErrorHandlerService: GlobalErrorHandlerService
  ) {}

  getInvestmentProducts() {
    return this.http.get(this.investmentProductUrl + '/ninfo').pipe(
      map((umbrellas: Umbrella[]) => {
        return umbrellas;
      }),
      catchError((err) => {
        return throwError(() =>
          this.globalErrorHandlerService.handleError(err)
        );
      })
    );
  }

  getInvestmentProductInfo(portfolio_id) {
    return this.http
      .get(this.investmentProductUrl + '/ninfo/' + portfolio_id)
      .pipe(
        map((fund: Fund) => {
          return fund;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }
}
