import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import CriiptoAuth from '@criipto/auth-js';
import { environment } from '../../environments/environment';
import { MitIDError, MitIDErrorType, MitIDResponse } from "../mitid";
import { ActivatedRoute } from "@angular/router";
import { first } from "rxjs";

const formatError = (error: MitIDErrorType) => {
    switch (error) {
        case "DatabaseError":
        default:
            return "Der skete en fejl. Prøv at oprette dig igen, eller kontakt os, for hjælp.";
    }
}

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent implements OnInit {
    mitidLoading: boolean = false;
    error: string | null = null;
    referenceId: string | null = null;
    advisorId: number | null = null;
    form = new FormGroup({
        email: new FormControl(
            {
                value: '',
                disabled: false,
            },
            {
                validators: [
                    Validators.required,
                    Validators.email,
                ],
            },
        ),
        fullname: new FormControl(
            {
                value: '',
                disabled: false,
            },
            {
                validators: [
                    Validators.required,
                ],
            },
        ),
        registrationType: new FormControl(
            {
                value: 'private',
                disabled: false,
            },
            {
                validators: [
                    Validators.required,
                ],
            },
        ),
        acceptTerms: new FormControl(
            {
                value: false,
                disabled: false,
            },
            {
                validators: [
                    Validators.required,
                ],
                updateOn: 'change',
            }
        ),
        acceptMailingList: new FormControl(
            {
                value: false,
                disabled: false,
            },
        ),
    });

    constructor(
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.route.paramMap.pipe(first()).subscribe(params => {
            if (params.has('advisor_id')) {
                this.advisorId = Number(params.get('advisor_id'));
            }
        });
    }

    async onSubmitRegistration() {
        const { email, fullname, registrationType, acceptTerms, acceptMailingList } = this.form.value;
        if (!acceptTerms) {
            this.mitidLoading = false;
            return;
        }

        this.mitidLoading = true;
        const response = await fetch(`${environment.apiUrl}/registration`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                full_name: fullname,
                registration_type: registrationType,
                mailing_list: acceptMailingList,
                advisor_id: this.advisorId,
            }),
        });
        const json: MitIDResponse = await response.json();
        this.mitidLoading = false;

        if (json.status === "ERROR") {
            this.handleError(json);
            return;
        }

        const criipto = new CriiptoAuth({
            domain: json.parameters.domain,
            clientID: json.parameters.client_id,
            store: sessionStorage,
        });

        const acrValue = registrationType === 'private'
            ? 'urn:grn:authn:dk:mitid:substantial'
            : 'urn:grn:authn:dk:mitid:business';

        criipto.redirect.authorize({
            redirectUri: json.parameters.redirect_uri,
            responseType: 'code',
            acrValues: [acrValue],
            state: json.parameters.state,
        });
    }

    private handleError(error: MitIDError) {
        this.error = formatError(error.type);
        if (this.error.includes('referencekode')) {
            this.referenceId = error.reference;
        }
    }
}