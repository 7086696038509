import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './authentication.guard';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { QaComponent } from './qa/qa.component';
import { NotFoundComponent } from './errors/not-found-componet';
import { GeneralErrorComponent } from './errors/general-error-component';
import { DbErrorComponent } from './errors/db-error-component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { InvestmentProductComponent } from './investment-products/investment-product/investment-product.component';
import { SessionExpiredComponent } from './errors/session-expired-componet';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Upload } from 'model-library';
import { AccountChoiceComponent } from './account-choice/account-choice.component';
import { AccountChoiceModule } from './account-choice/account-choice.module';
import { RegistrationComponent } from './registration/registration.component';
import { RegistrationCallbackComponent } from './registration-callback/registration-callback.component';
import { LoginCallbackComponent } from './login-callback/login-callback.component';
import { AppNavigationComponent } from './app-navigation/app-navigation.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  onSameUrlNavigation: 'reload',
  // enableTracing: true,
};

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/dashboard',
  },
  {
    path: 'oprettelse/mitid',
    component: RegistrationCallbackComponent,
  },
  {
    path: 'oprettelse/:advisor_id',
    component: RegistrationComponent,
  },
  {
    path: 'oprettelse',
    component: RegistrationComponent,
  },
  {
    path: 'login/mitid',
    component: LoginCallbackComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: AppNavigationComponent,
    children: [

      {
        path: 'upload',
        component: Upload,
      },
      /*investment-product*/
      {
        path: 'investment-products',
        loadChildren: () =>
          import('./investment-products/investment-products.module').then(
            (m) => m.InvestmentProductsModule
          ),
      },
      {
        /*overblik*/
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'investment-product/:portfolio_id',
        component: InvestmentProductComponent,
      },
      {
        /*Investeringsvalg*/
        path: 'investment-choice',
        loadChildren: () =>
          import('./investment-choice/investment-choice.module').then(
            (m) => m.InvestmentChoiceModule
          ),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'sell',
        loadChildren: () => import('./sell/sell.module').then((m) => m.SellModule),
        canActivate: [AuthenticationGuard],
      },
      {
        /*Rebalancering*/
        path: 'account/rebalance',
        loadChildren: () =>
          import('./legacy-components/rebalance/rebalance.module').then(
            (m) => m.RebalanceModule
          ),
        canActivate: [AuthenticationGuard],
      },
      {
        /*Ordre status*/
        path: 'order-status',
        loadChildren: () =>
          import('./order-status/order-status.module').then(
            (m) => m.OrderStatusModule
          ),
        canActivate: [AuthenticationGuard],
      },
      {
        /*Dokumenter*/
        path: 'documents',
        loadChildren: () =>
          import('./documents/documents.module').then((m) => m.DocumentsModule),
        canActivate: [AuthenticationGuard],
      },

      {
        /*Kontoudtog*/
        path: 'statement',
        loadChildren: () =>
          import('./reports/statement/statement.module').then(
            (m) => m.StatementModule
          ),
        canActivate: [AuthenticationGuard],
      },
      {
        /*Afkastrapport*/
        path: 'return',
        loadChildren: () =>
          import('./reports/return-report/return-report.module').then(
            (m) => m.ReturnReportModule
          ),
        canActivate: [AuthenticationGuard],
      },
      {
        /*Skatterapport*/
        path: 'account/taxreport',
        loadChildren: () =>
          import('./legacy-components/account/tax-report/tax-report.module').then(
            (m) => m.TaxReportModule
          ),
        canActivate: [AuthenticationGuard],
      },
      {
        /*Kontakt fundmarket*/
        path: 'messages',
        loadChildren: () =>
          import('./messages/messages.module').then((m) => m.MessagesModule),
        canActivate: [AuthenticationGuard],
      },
      {
        /*Profil*/
        path: 'profil',
        component: ProfileComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        /*Reelle ejere*/
        path: 'profil/realowner',
        component: ProfileComponent,
        data: { realowner: true },
        canActivate: [AuthenticationGuard],
      },
      {
        /*aml*/
        path: 'profil/aml',
        component: ProfileComponent,
        data: { aml: true },
        canActivate: [AuthenticationGuard],
      },
      {
        /*person-info*/
        path: 'profil/personinfo',
        component: ProfileComponent,
        data: { personinfo: true },
        canActivate: [AuthenticationGuard],
      },
      {
        /*Kontovalg*/
        path: 'account-choice',
        component: AccountChoiceComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        /*Selvangivelse*/
        path: 'taxform',
        loadChildren: () =>
          import('./legacy-components/tax-form/tax-form.module').then(
            (m) => m.TaxFormModule
          ),
        canActivate: [AuthenticationGuard],
      },
      /*logud*/
      {
        /*Spørgsmål vedr. hvidvask*/
        path: 'aml',
        loadChildren: () => import('./aml/aml.module').then((m) => m.AmlModule),
        canActivate: [AuthenticationGuard],
      },
      {
        /*Ofte stillede spørgsmål*/
        path: 'qa',
        component: QaComponent,
      },

      {
        path: 'onboarding',
        component: OnboardingComponent,
        canActivate: [AuthenticationGuard],
      },

      { path: 'error', component: GeneralErrorComponent },
      { path: 'dberror', component: DbErrorComponent },
      { path: '404', component: NotFoundComponent },
      { path: '401', component: SessionExpiredComponent },

      { path: '**', redirectTo: '/404', pathMatch: 'full' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
