import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-db-error-component',
  templateUrl: './db-error-component.html',
})
export class DbErrorComponent {
  constructor() {}

  
  
}
