import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
    selector: 'app-prenavigation',
    templateUrl: './app-prenavigation.component.html',
    styleUrls: ['./app-prenavigation.component.css'],
})
export class AppPreNavigationComponent {
    constructor() { }
}
