import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { GlobalErrorHandlerService } from '../services/global-error-handler.service';

@Component({
  selector: 'app-session-expired-component',
  templateUrl: './session-expired-component.html',
})
export class SessionExpiredComponent {
  constructor() {}

  
}
