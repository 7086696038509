<div class="my-3 col-12 col-lg-8">
  <div class="card">
    <div class="card-body">
      <h2>Brugeroplysninger</h2>
      <p>Her kan du vælge brugeroplysninger til login.</p>
      <p>
        Efter oprettelse kan du logge ind med brugernavn og password - samt ved
        hjælp af en sms-kode du modtager.
      </p>

      <p>Din adgangskode skal indeholde mindst 6 tegn i alt samt mindst 1 stort
        bogstav, 1 småt bogstav og 1 tal.</p>

      <form [formGroup]="userinfo" (ngSubmit)="saveUserinfo()">
        <div class="mb-3 row">
          <div class="col-lg-4 col-md-6 col-sm-11">
            <label class="required" for="username">Brugernavn</label>
            <input type="text" class="form-control" id="username" formControlName="username"
              placeholder="Skriv dit brugernavn" autocomplete="true" required />
            <div *ngIf="
                userinfo.controls['username'].invalid &&
                (userinfo.controls['username'].dirty ||
                  userinfo.controls['username'].touched)
              " class="p-2 alert alert-danger">
              Du mangler at angive et brugernavn.
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <div class="col-lg-4 col-md-6 col-10">
            <label class="required" for="password">Adgangskode</label>
            <input [type]="hide ? 'password' : 'text'" class="form-control" formControlName="password" id="password"
              placeholder="Skriv din adgangskode" autocomplete="new-password" required />
            <div *ngIf="userinfo.controls['password'].invalid && userinfo.controls['password'].touched"
              class="p-2 alert">
              Adgangskoden overholder ikke kriterierne beskrevet ovenfor.
            </div>
          </div>
          <div class="col-lg-1 col-1 align-self-end">
            <mat-icon (click)="toggleHidePassword()">{{
              hide ? "visibility_off" : "visibility"
              }}</mat-icon>
          </div>
        </div>
        <div class="mb-3 row">
          <div class="col-sm">
            <span *ngIf="credentials.psw_date" class="">
              Senest ændret {{ credentials.psw_date }}
            </span>

            <div class="mb-3 mt-3 row">
              <div class="col-auto" *ngIf="!onboarding">
                <button (click)="loading = true" [disabled]="!userinfo.valid" type="submit" id="button"
                  class="btn btn-primary ml-3">
                  Gem
                </button>
              </div>
              <div class="col-auto">
                <div *ngIf="info" class="text-success mt-3">{{ info }}</div>
                <div *ngIf="error" class="text-danger mt-3">{{ error }}</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-spinner [spinning]=loading></app-spinner>