import { Component } from '@angular/core';

@Component({
  selector: 'app-general-error-component',
  templateUrl: './general-error-component.html',
})
export class GeneralErrorComponent {
  constructor() {}

  
  
}
