import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private not: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor() {}

  getNots(): Observable<boolean> {
    return this.not.asObservable();
  }

  setNotObs(not: boolean) {    
    this.not.next(not);
  }
}
