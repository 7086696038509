import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { Country } from '@sharedmodels/country.model';
import { Profile } from '@sharedmodels/profile.model';
import { RealOwner } from '@sharedmodels/realowner.model';
import { Resp } from '@sharedmodels/resp.model';
import { CountriesService } from 'src/app/services/countries.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-person-info',
  templateUrl: './person-info.component.html',
})
export class PersonInfoComponent implements OnInit {
  profile: Profile = <Profile>{};
  res: Resp = <Resp>{};
  realOwner: RealOwner = <RealOwner>{};
  realOwners: RealOwner[];
  count: Country = <Country>{};
  count1: Country = <Country>{};
  count2: Country = <Country>{};
  address1: string;
  loading: boolean = false;
  realowner: boolean = false;
  employee: boolean = false;
  investor: boolean = false;
  country: Country = <Country>{};
  countries = new Array<Country>();
  personinfo: FormGroup;
  info: string = '';
  error: string = '';
  bolandcount = <any>[];
  pristatcount = <any>[];
  sekstatcount = <any>[];
  keyword = 'name';

  @Output() goForward = new EventEmitter<boolean>();
  @Input() onboarding?: boolean;

  constructor(
    private profileService: ProfileService,
    private countriesService: CountriesService,
    public cdf: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getCountries();
    this.getInfo();

    this.personinfo = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50),
      ]),
      adresse1: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50),
      ]),
      adresse2: new FormControl(''),
      postby: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50),
      ]),
      tel: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        Validators.minLength(6),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(50),
        Validators.minLength(2),
      ]),
      taxnumber: new FormControl(''),
      country_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        Validators.minLength(2),
      ]),
      citizen1_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        Validators.minLength(2),
      ]),
      cpr: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      citizen2_name: new FormControl('', []),
      citizen1_id: new FormControl(),
      citizen2_id: new FormControl(),
      country_id: new FormControl(),
    });

    if (this.onboarding) {
      this.personinfo.statusChanges
        .pipe(distinctUntilChanged())
        .subscribe((status) => {
          this.onFormValidation(status);
        });
    }
  }

  onFormValidation(validity: string) {
    switch (validity) {
      case 'VALID':
        this.goForward.emit(true);
        break;
      case 'INVALID':
        this.goForward.emit(false);
        break;
      default:
        this.goForward.emit(false);
        break;
    }
  }

  getCountries() {
    this.loading = true;
    this.countriesService.getCountries().subscribe((countries: Country[]) => {
      this.loading = false;
      this.countries = [...countries];
    });
  }

  showSection() {
    if (this.profile?.realowner_count > 0) {
      return (this.realowner = true);
    } else if (this.profile?.employee_yn === 'Y') {
      this.personinfo.get('cpr').removeValidators(Validators.required);
      this.personinfo.get('country_name').removeValidators(Validators.required);
      this.personinfo
        .get('citizen1_name')
        .removeValidators(Validators.required);
      this.personinfo
        .get('citizen2_name')
        .removeValidators(Validators.required);
      return (this.employee = true);
    } else {
      return (this.investor = true);
    }
  }

  saveInfo(): Observable<boolean> {
    const result = new Subject<boolean>();
    this.info = '';
    this.error = '';
    this.loading = true;
    this.profile.name = this.personinfo.get('name')?.value;
    this.profile.address1 = this.personinfo.get('adresse1')?.value;
    this.profile.address2 = this.personinfo.get('address2')?.value;
    this.profile.zipcity = this.personinfo.get('postby')?.value;
    this.profile.email = this.personinfo.get('email')?.value;
    this.profile.tin = this.personinfo.get('taxnumber')?.value;
    this.profile.phone = this.personinfo.get('tel')?.value;
    this.profile.cpr = this.personinfo.get('cpr')?.value;

    this.profileService.saveProfile(this.profile).subscribe((res: Resp) => {
      this.loading = false;
      this.res = res;
      if (res?.status === 'ERROR') {
        this.error = res.message;

        this.cdf.detectChanges();
        result.next(false);
        result.complete();
      } else if (res?.severity === 'ERROR') {
        this.error = res.hint;
        this.cdf.detectChanges();
        result.next(false);
        result.complete();
      } else if (res?.status === 'OK') {
        this.info = 'Dine ændringer er nu gemt.';
        result.next(true);
        result.complete();
      }
      this.cdf.detectChanges();
      return false;
    }),
      (error) => {
        this.loading = false;
        this.error = error;
        result.next(true);
        result.complete();
      };
    return result.asObservable();
  }

  getInfo() {
    this.loading = true;
    this.profileService.getInfo().subscribe((profile: Profile) => {
      this.loading = false;
      this.profile = profile;

      this.count.name = this.profile.country_name;
      this.count.id = this.profile.country_id;

      this.count1.name = this.profile.citizen1_name;
      this.count1.id = this.profile.citizen1_id;

      this.count2.name = this.profile.citizen2_name;
      this.count2.id = this.profile.citizen2_id;

      this.personinfo.patchValue({
        name: this.profile.name,
        adresse1: this.profile.address1,
        address2: this.profile.address2,
        postby: this.profile.zipcity,
        email: this.profile.email,
        taxnumber: this.profile.tin,
        tel: this.profile.phone,
        cpr: this.profile.cpr,
        country_name: this.count.name,
        country_id: this.count.id,
        citizen1_name: this.count1.name,
        citizen1_id: this.count1.id,
        citizen2_name: this.count2.name,
        citizen2_id: this.count2.name,
      });
      //this.saveInfo();

      this.showSection();
      this.cdf.detectChanges();
    });
  }

  selectCitizen1(country: Country) {
    this.profile.citizen1_name = country.name;
    this.profile.citizen1_id = country.id;
  }

  selectCitizen2(country: Country) {
    this.profile.citizen2_name = country.name;
    this.profile.citizen2_id = country.id;
  }

  selectCountry_name(country: Country) {
    this.profile.country_name = country.name;
    this.profile.country_id = country.id;
  }

  clearCountry_name() {
    this.profile.country_name = '';
    this.profile.country_id = null;
  }

  clearCitizen1() {
    this.profile.citizen1_name = '';
    this.profile.citizen1_id = null;
  }

  clearCitizen2() {
    this.profile.citizen2_name = '';
    this.profile.citizen2_id = null;
  }

  customFilter = function (countries: Country[], query: string): any[] {
    return countries.filter((x) =>
      x.name?.toLowerCase().includes(query.toLowerCase())
    );
  };
}
