import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Custody } from '@sharedmodels/custody.model';
import { Custodyuniverse } from '@sharedmodels/custodyuniverse.model';
import { Resp } from '@sharedmodels/resp.model';
import { Order } from '@sharedmodels/order.model';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { Transaction } from '@sharedmodels/transaction.model';
import { EMPTY, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustodyService {
  custodiesUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private globalErrorHandlerService: GlobalErrorHandlerService
  ) {}

  getCustodyUniverse() {
    return this.http
      .get(this.custodiesUrl + '/user/custodyuniverse', {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((custodyunivereses: Custodyuniverse[]) => {
          return custodyunivereses;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  getCustodies() {
    return this.http
      .get(this.custodiesUrl + '/user/custodies', {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((custodies: Custody[]) => {
          return custodies;
        }),
        catchError((err) => {
         return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
         
        })
      );
  }

  getCustody(custody_id) {
    return this.http
      .get(this.custodiesUrl + '/custody/' + custody_id, {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((custody: Custody) => {
          return custody;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  changeCustodyName(id: number, newName: string) {
    return this.http
      .put<String>(
        this.custodiesUrl + '/user/custodies/' + id + '/name',
        { name: newName },
        {
          headers: new HttpHeaders({
            fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
            Accept: 'multipart/form-data',
          }),
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          this.globalErrorHandlerService.handleError(err);
          return throwError(() => err);
        })
      );
  }

  changeContactName(id: number, newName: string) {
    return this.http
      .put(
        this.custodiesUrl + '/user/custodies/' + id + '/contactname',
        { contact_name: newName },
        {
          headers: new HttpHeaders({
            fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
            Accept: 'multipart/form-data',
          }),
        }
      )
      .pipe(
        map((res: Resp) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }
  changeContactEmail(id: number, newEmail: string) {
    return this.http
      .put(
        this.custodiesUrl + '/user/custodies/' + id + '/contactemail',
        { contact_email: newEmail },
        {
          headers: new HttpHeaders({
            fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
            Accept: 'multipart/form-data',
          }),
        }
      )
      .pipe(
        map((res: Resp) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  createCustodies(custody: Custody) {
    return this.http
      .post(this.custodiesUrl + '/custody', custody, {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
          Accept: 'multipart/form-data',
        }),
      })
      .pipe(
        map((res: Resp) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  saveInvestmentChoice(custody_id, model_id, funds) {
    return this.http
      .post(
        this.custodiesUrl + '/custody/' + custody_id + '/shoplist',
        { model_id: model_id, data: funds },
        {
          headers: new HttpHeaders({
            fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
            Accept: 'multipart/form-data',
          }),
        }
      )
      .pipe(
        map((res: Resp) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  getOrders() {
    return this.http
      .get(this.custodiesUrl + '/user/cart', {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((orders: Order[]) => {
          return orders;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  deleteTransaction(transaction: Transaction) {
    return this.http
      .delete(this.custodiesUrl + '/user/cart/' + transaction?.transaction_id, {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }
}
