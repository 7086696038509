import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';
import { Credential } from '@sharedmodels/credential.model';
import { Employee } from '@sharedmodels/employee.model';
import { Profile } from '@sharedmodels/profile.model';
import { RealOwner } from '@sharedmodels/realowner.model';
import { Resp } from '@sharedmodels/resp.model';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { Upload } from '@sharedmodels/upload/upload.model';
import { throwError } from 'rxjs/internal/observable/throwError';

export type UserCredentials = {
  username: string,
  password: string,
};

export type UserCredentialsResponse = {
  status: "OK"
} | {
  status: "ERROR",
  type: string,
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  userUrl: string = environment.apiUrl;
  constructor(
    private http: HttpClient,
    public globalErrorHandlerService: GlobalErrorHandlerService
  ) { }
  getInfo() {
    return this.http
      .get(this.userUrl + '/user/info/', {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((profile: Profile) => {
          return profile;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  getFiles() {
    return this.http
      .get(this.userUrl + '/user/files/', {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((files: Upload[]) => {
          return files;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  uploadFile(file) {
    return this.http
      .post(this.userUrl + '/user/register/upload', file, {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
          Accept: 'multipart/form-data',
        }),
      })
      .pipe(
        map((res: Resp) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  getCredentials() {
    return this.http
      .get(this.userUrl + '/user/credentials/', {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((credentials: Credential) => {
          return credentials;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  getRealowners() {
    return this.http
      .get(this.userUrl + '/user/realowners/', {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((realOwners: RealOwner[]) => {
          return realOwners;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  saveRealowner(realOwner: RealOwner) {
    return this.http
      .put(this.userUrl + '/user/realowner/' + realOwner.id, realOwner, {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
          Accept: 'multipart/form-data',
        }),
      })
      .pipe(
        map((res: Resp) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  saveEmployee(employee: Employee) {
    return this.http
      .post(this.userUrl + '/user/info', employee, {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
          Accept: 'multipart/form-data',
        }),
      })
      .pipe(
        map((res: Resp) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  saveProfile(profile: Profile) {
    return this.http
      .post(this.userUrl + '/user/info', profile, {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
          Accept: 'multipart/form-data',
        }),
      })
      .pipe(
        map((res: Resp) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  saveUserCredentials(userCredentials: UserCredentials): Promise<UserCredentialsResponse> {
    return fetch(`${this.userUrl}/user/credentials`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        fundmarkettoken: sessionStorage.getItem('APIToken1'),
      },
      body: JSON.stringify(userCredentials),
    })
      .then((res) => res.json())

  }
}
