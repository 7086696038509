import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isLoggedIn().pipe(
      map((response) => {
        if (response) {
          this.authService.getLogin().subscribe((user) => {
            if (user?.onboarding) {
              this.router.navigateByUrl('onboarding');
            }
            return true;
          });
          return true;
        } else {
          this.router.navigateByUrl('login');
          return false;
        }
      })
    );
  }
}
