<nav>
    <a class="logo-link" href="/">
        <img class="logo" src="../../assets/images/fundmarket-logo-translucent.svg" />
    </a>
    <div class="nav-content">
        <div class="links">
            <a class="nav-link" href="/investment-products">Investeringsfonde</a>
            <a class="nav-link" href="/qa">Ofte stillede spørgsmål</a>
        </div>
    </div>
</nav>