<div class="card m-3">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-5 col-sm-12 mt-3">
        <h2>Ofte stillede spørgsmål</h2>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="my-3 col-12 col-lg-8">
      <div class="card">
        <!--Spørgsmål 1-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q1"
            >
              Hvor kan jeg få hjælp til at investere?
            </button>
            <div class="collapse" id="q1">
              <div class="card-body">
                Du kan tage kontakt til en investeringsrådgiver. Fundmarket
                henviser til Finanstilynets liste over investeringsrådgivere på
                Finanstilsynets hjemmeside.
              </div>
            </div>
          </div>
        </div>

        <!--Spørgsmål 2-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q16"
            >
              Hvor kan jeg få mere viden om produkterne?
            </button>
            <div class="collapse" id="q16">
              <div class="card">
                <div class="card-body">
                  Du kan tilgå dokumenter for hver afdeling i menupunktet
                  <router-link class="link">
                    <a href="market">Investeringsfonde</a> </router-link
                  >, hvorefter du vælger en afdeling. Her fremgår prospekter og
                  Central Investorinformation. På investeringsfondens
                  hjemmeside,
                  <a href="http://www.portfoliomanager.dk" target="_blank"
                    >www.portfoliomanager.dk</a
                  >, kan du finde yderligere information såsom årsrapporter, mm.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Spørgsmål 3-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q5"
            >
              Hvad koster det at handle investeringsfonde hos Fundmarket?
            </button>
            <div class="collapse" id="q5">
              <div class="card card-body">
                Det koster ikke noget. Du betaler ikke nogen gebyrer til
                Fundmarket. Det er gratis at oprette en konto og det er gratis
                at handle fra kontoen. Fundmarket tager heller ingen kurtage.
              </div>
            </div>
          </div>
        </div>

        <!--Spørgsmål 4-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q9"
            >
              Hvordan køber jeg på Fundmarket?
            </button>
            <div class="collapse" id="q9">
              <div class="card card-body">
                Du køber ved at oprette et investeringsvalg, hvor du udvælger
                din ønskede fordeling af de investeringsfonde, der udbydes på
                Fundmarket. Dit investeringskontonummer, som du har fået tildelt
                din profil ved oprettelsen er et bankkontonummer, som du skal
                overføre det ønskede beløb til.
              </div>
            </div>
          </div>
        </div>
        <!--Spørgsmål 5-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q10"
            >
              Hvordan sælger jeg på Fundmarket?
            </button>
            <div class="collapse" id="q10">
              <div class="card">
                <div class="card-body">
                  I menuen til venstre vælger du
                  <router-link class="link">
                    <a href="sell">Salg</a>.
                  </router-link>
                  Her kan du sælge investeringsbeviser.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Spørgsmål 6-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q11"
            >
              Hvordan hæver jeg penge på Fundmarket?
            </button>
            <div class="collapse" id="q11">
              <div class="card">
                <div class="card-body">
                  I menuen til venstre vælger du
                  <router-link class="link">
                    <a href="sell">Salg</a> </router-link
                  >. Her kan du sælge investeringsbeviser.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Spørgsmål 7-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q13"
            >
              Hvor kan jeg se mine handelsnotaer?
            </button>
            <div class="collapse" id="q13">
              <div class="card">
                <div class="card-body">
                  I menuen til venstre vælger du rapporter og herefter
                  <router-link class="link">
                    <a href="account/statement">Kontoudtog</a> </router-link
                  >. Under hver transaktion er der tilknyttet en handelsnota. På
                  forsiden kan du se de seneste 5 bevægelser på kontoen.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Spørgsmål 8-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q14"
            >
              Hvor kan jeg se afkast på min investering?
            </button>
            <div class="collapse" id="q14">
              <div class="card">
                <div class="card-body">
                  I menuen til venstre vælger du rapporter og
                  <router-link class="link">
                    <a href="account/return">Afkastrapport</a> </router-link
                  >. Her kan du se dine afkast og dekomponering heraf.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Spørgsmål 9-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q21"
            >
              Hvor kan jeg se historiske afkast på afdelingerne?
            </button>
            <div class="collapse" id="q21">
              <div class="card">
                <div class="card-body">
                  <p>
                    På investeringsfondens hjemmeside
                    <a href="http://www.portfoliomanager.dk" target="_blank"
                      >www.portfoliomanager.dk</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Spørgsmål 10-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q20"
            >
              Hvor kan jeg se omkostninger på afdelingerne?
            </button>
            <div class="collapse" id="q20">
              <div class="card">
                <div class="card-body">
                  <p>
                    Du kan tilgå dokumenter for hver afdeling i menupunktet
                    <router-link class="link">
                      <a href="market">Investeringsfonde</a> </router-link
                    >, hvorefter du vælger en afdeling. Her fremgår alle
                    omkostninger.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Spørgsmål 11-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q18"
            >
              Hvordan bliver jeg beskattet af min investering?
            </button>
            <div class="collapse" id="q18">
              <div class="card">
                <div class="card-body">
                  <p>
                    Hvis du er skattepligtig i Danmark, så kan du se
                    skatteforholdene i nedenstående tabel.
                  </p>
                  <app-table></app-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Spørgsmål 12-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q19"
            >
              Skal jeg selv indberette noget til SKAT?
            </button>
            <div class="collapse" id="q19">
              <div class="card">
                <div class="card-body">
                  <p>
                    Ikke hvis du kun er skattepligtig i Danmark. Fundmarket
                    indberetter oplysninger om dine investeringer til
                    Skattestyrelsen en gang om året.<br />
                    Hvis du har et dansk CPR-nummer, men er skattepligtig i et
                    andet land, så skal du oplyse dit TIN-nummer (Tax
                    Identification Number). Så indberetter vi til de danske
                    myndigheder, med denne oplysning. Om de danske myndigheder
                    så indberetter videre, ved vi ikke.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Spørgsmål 13-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q22"
            >
              Jeg modtager overførselsindkomst fra den danske stat. Har det
              betydning for min opsparing på Fundmarket?
            </button>
            <div class="collapse" id="q22">
              <div class="card">
                <div class="card-body">
                  <p>
                    Ja, i mange tilfælde modregnes afkastet i din
                    overførselsindkomst. Tal evt. med en investeringsrådgiver om
                    dette.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Spørgsmål 14-->
        <div class="m-3">
          <div class="">
            <button
              class="btn btn-primary"
              data-bs-toggle="collapse"
              role="button"
              data-bs-target="#q4"
            >
              Hvordan er jeg stillet, hvis Fundmarket lukker?
            </button>
            <div class="collapse" id="q4">
              <div class="card card-body">
                Dine penge er investeret gennem en investeringsfond, som er
                uafhængig af Fundmarket. Fundmarket er blot
                administrationsselskab for investeringsfonden. I tilfælde af at
                Fundmarket lukker, vil investeringsfondens bestyrelse finde et
                andet administrationsselskab, og din investering bliver ikke
                påvirket af dette.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
