import { Component } from '../../shared/component';
import { html } from 'cedk';

export class Icon extends Component {
  static define() {
    super.define('fm-icon');
  }

  static get style() {
    return {
      ':host': {
        'font-family': 'Material Icons',
        'font-weight': 'normal',
        'font-style': 'normal',
        'font-size': 'var(--icon-size, 24px)',
        'line-height': '1',
        'letter-spacing': 'normal',
        'text-transform': 'none',
        'display': 'inline-block',
        'white-space': 'nowrap',
        'word-wrap': 'normal',
        'direction': 'ltr',
        '-webkit-font-feature-settings': '"liga"',
        'font-feature-settings': '"liga"',
        '-webkit-font-smoothing': 'antialiased',
        'color': '#666',
        'padding': '4px',
        'cursor': 'pointer'
      }
    }
  }

  static get properties() {
    return {};
  }

  static get template() {
    return html`<slot></slot>`;
  }
}

export { Icon as IconElement };