import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { AmlComponent } from '../aml/aml.component';
import { Profile } from '@sharedmodels/profile.model';
import { RealOwner } from '@sharedmodels/realowner.model';
import { User } from '@sharedmodels/user.model';
import { PersonInfoComponent } from '../profile/person-info/person-info.component';
import { AuthService } from '../services/auth.service';
import { ProfileService } from '../services/profile.service';
import { AmlOnboardingFirstComponent } from '../aml/aml-onboarding-first/aml-onboarding-first.component';
import { OnboardingCompleteComponent } from './onboarding-complete.component';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingComponent implements OnInit, AfterViewInit {
  user = <User>{};
  prof: Profile = <Profile>{};
  loading: boolean = false;
  profile: FormGroup;
  upload: FormGroup;
  kontovalg: FormGroup;
  realowner: FormGroup;
  questions: FormGroup;
  first: FormGroup;
  finale: FormGroup;
  userinfo: FormGroup;
  dataFromChild: boolean;
  realOwners: RealOwner[];
  forwardEI: boolean;
  forwardRI: boolean;
  forwardPI: boolean;
  forwardUID: boolean;
  forwardACC: boolean;
  forwardQ: boolean;
  index: number;
  isCompleted: boolean;
  select: string = '';
  saveInfoDone: any = false;

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(AmlComponent) private qStep: AmlComponent;
  @ViewChild(PersonInfoComponent) private pStep: PersonInfoComponent;
  @ViewChild(AmlOnboardingFirstComponent)
  private qStepFirst: AmlOnboardingFirstComponent;
  @ViewChild(OnboardingCompleteComponent)
  private ocStep: OnboardingCompleteComponent;
  constructor(
    private authService: AuthService,
    private _formBuilder: FormBuilder,
    private profileService: ProfileService,
    private router: Router
  ) {}
  ngOnInit() {
    this.profile = this._formBuilder.group({});
    this.upload = this._formBuilder.group({});
    this.kontovalg = this._formBuilder.group({});
    this.realowner = this._formBuilder.group({});
    this.finale = this._formBuilder.group({});
    this.questions = this._formBuilder.group({});
  }

  ngAfterViewInit() {
    this.getLogin();
  }

  getLogin() {
    this.loading = true;
    this.getInfo();

    this.authService.getLogin().subscribe((user: User) => {
      this.loading = false;
      this.user = user;
      if (!this.user?.onboarding) {
        this.router?.navigateByUrl('dashboard');
      }
      if (!user.registered) {
        this.move(0);
      } else if (!user.has_uploaded) {
        this.move(2);
      } else if (!user.has_custodies) {
        this.move(3);
      } else if (user.has_questions) {
        this.move(4);
      }
    });
  }

  getInfo() {
    this.loading = true;
    this.profileService.getInfo().subscribe((profile: Profile) => {
      this.loading = false;
      this.prof = profile;
    });
  }

  move(index: number) {
    this.loading = true;
    this.stepper.selectedIndex = index;
    this.loading = false;
  }

  eventPI(data: boolean) {
    this.forwardPI = data;
  }

  clickPstep() {
    this.saveInfoDone = this.pStep.saveInfo().subscribe((data) => {
      if (data) {
        this.stepper?.next();
      }
    });
  }

  eventUID(data: boolean) {
    //siden før de første spørgsmål
    this.qStepFirst?.fetchQuestions();
    this.forwardUID = data;
  }

  eventACC(data: boolean) {
    this.qStep?.fetchQuestions();
    this.forwardACC = data;
  }

  eventQall(data: boolean) {
    this.forwardQ = data;
  }

  nextStep() {
    this.qStep?.fetchQuestions();
    this.stepper.next();
  }

  submitOnboarding() {
    this.ocStep?.submitOnboarding();
    this.stepper.next();
  }

  logaf() {
    this.authService.logout();
  }
}
