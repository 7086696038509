<div class="table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col">Afdelinger</th>
        <th scope="col">Udbyttebetalende</th>
        <th colspan="2" class="text-center">Frie midler/Børn</th>
        <th colspan="2" class="text-center">VSO-midler</th>
        <th colspan="2" class="text-center">Selskaber</th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th>
          Opgøreslesmetode<br />
          for avancer
        </th>
        <th>Beskatning</th>
        <th>
          Opgøreslesmetode<br />
          for avancer
        </th>
        <th>Beskatning</th>
        <th>
          Opgøreslesmetode<br />
          avancer
        </th>
        <th>Beskatning</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Optimal Danske aktier, kl f</td>        
        <td>Realisationsprincip</td>
        <td>Aktieindkomst</td>
        <td colspan="2" style="text-align: center">Ikke muligt</td>
        <td>Lagerprincip</td>
        <td>Selskabsindkomst</td>
      </tr>
      <tr>
        <td>Optimal Danske obligationer, kl f</td>
        <td class="text-center">Ja</td>
        <td>Realisationsprincip</td>
        <td>Kapitalindkomst</td>
        <td colspan="2" class="text-center">Ikke muligt</td>
        <td>Lagerprincip</td>
        <td>Selskabsindkomst</td>
      </tr>
      <tr>
        <td>
          Optimal Globale aktier, kl f<br />
          Optimal Alternative Investeringer, kl f<br />
          Optimal Balance Mix, kl f<br />
          Hammers Fonde - høj, kl f (fra 2021)
        </td>
        <td class="text-center">Nej</td>
        <td>Lagerprincip</td>
        <td>Aktieindkomst</td>
        <td>Lagerprincip</td>
        <td>Selskabsindkomst</td>
        <td>Lagerprincip</td>
        <td>Selskabsindkomst</td>
      </tr>
      <tr>
        <td>
          Optimal Globale Obligationer, kl f<br />
          Hammers Fonde - Lav, kl f<br />
          Hammers Fonde - Høj, kl f (2020)
        </td>
        <td class="text-center">Nej</td>
        <td>Lagerprincip</td>
        <td>Kapitalindkomst</td>
        <td>Lagerprincip</td>
        <td>Selskabsindkomst</td>
        <td>Lagerprincip</td>
        <td>Selskabsindkomst</td>
      </tr>
    </tbody>
    <tfoot></tfoot>
  </table>
</div>
