/**
 * Checks that an element has a non-empty `name` and `value` property.
 * 
 * @private
 * @param  {Element} element  The element to check.
 * @return {Boolean}          `true` if the element is an input, `false` otherwise.
*/
export function isControlElement (element) {
  return element.name && element.value != null;
}

/**
 * Checks if an element is a checkbox-control.
 * 
 * @private
 * @param  {Element} element  The element to check
 * @return {Boolean}          `true` if the element is a checkbox, `false` otherwise.
*/
export function isCheckbox (element) {
  const checkbox = '[type=checkbox], [role=checkbox]';
  return element.matches(checkbox);
}

/**
 * Checks if an input is an `input` with `type="radio"`.
 * 
 * @private
 * @param {Element} element The element to check.
 * @return {Boolean}        `true` if the element is a radio, `false` otherwise.
 * 
 */
export function isNativeRadio (element) {
  const radio = 'input[type=radio]';
  return element.matches(radio);
}

/**
 * Checks if an element is a radio-control.
 * 
 * @private
 * @param {Element} element The element to check.
 * @return {Boolean}        `true` if the element is a radio, `false` otherwise.
 * 
 */
export function isRadio (element) {
  const radio = '[type=radio], [role=radio]';
  return element.matches(radio);
}

/**
 * Checks if an input is a `select` with the `multiple` attribute.
 * 
 * @private
 * @param  {Element} element  The element to check
 * @return {Boolean}          `true` if the element is a multiselect, `false` otherwise.
*/
export function isNativeMultiSelect (element) {
  const multiselect = 'select[multiple]';
  return element.matches(multiselect);
}

/**
 * Retrieves the selected options from a multi-select as an array.
 * 
 * @private
 * @param {HTMLSelectElement} options  The options for the select.
 * @return {Array}                     An array of selected option values.
*/
export function getSelectValues(element) {
  return Array.from(element.options).reduce((values, option) => {
    return option.selected ? values.concat(option.value) : values;
  }, []);
}

/**
 * Validates an element.
 * 
 * Uses the Constraint Validation API if available,
 * falling back to either `element.validate()` or `element.valid`.
 * 
 * @private
 * @param {Element} element 
 * @return {Boolean} `true` if the element is valid, `false` otherwise.
 */
export function validateElement(element) {
  if (element.validity) {
    return element.validity.valid;
  } else if (element.hasOwnProperty('validate')) {
    return element.validate();
  } else if (element.hasOwnProperty('valid')) {
    return element.valid;
  } else {
    return true;
  }
}