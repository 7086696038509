import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { SENTRY_DSN, VERSION } from './env';

console.log('Environment:', environment.name);
console.log('Version:', VERSION);

if (environment.production) {
  enableProdMode();
}

if (environment.staging || environment.production) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    environment: environment.name,
    release: VERSION,
    tracesSampleRate: 0.6,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
