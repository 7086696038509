<div class="login-background"></div>
<app-prenavigation></app-prenavigation>
<div class="card">
    <h2>Log ind</h2>
    <div *ngIf="error" class="error">
        <div>{{ error }}</div>
        <app-error-reference-field *ngIf="referenceId" [referenceId]="referenceId"></app-error-reference-field>
    </div>
    <div *ngIf="stage === 'password'" class="card-form">
        <form [formGroup]="form">
            <div class="form-field">
                <label for="username" class="text-input-label">Brugernavn</label>
                <input id="username" type="text" class="text-input" formControlName="username" required />
            </div>

            <div class="form-field">
                <label for="password" class="text-input-label">Password</label>
                <input id="password" type="password" class="text-input" formControlName="password" required />
            </div>

            <button class="button-login" [disabled]="!form.value.username" (click)="onClickLogin()">
                <span *ngIf="!loginLoading">Log ind</span>
                <app-progress-dots *ngIf="loginLoading"></app-progress-dots>
            </button>

            <div class="divider"><span>eller</span></div>

            <app-mitid-button [loading]="mitidLoading" (click)="onClickMitID()"></app-mitid-button>
        </form>
        <div class="registration-note">Ikke på Fundmarket endnu? <a href="/oprettelse">Opret dig her</a></div>
    </div>
    <div *ngIf="stage === 'sms'" class="card-form">
        <form [formGroup]="smsForm">
            <div class="form-field">
                <label for="smsCode" class="text-input-label">SMS Kode</label>
                <input id="smsCode" type="text" class="code-input" formControlName="smsCode" maxlength="5" required />
            </div>

            <button class="button-login" (click)="onSubmitSMS()">
                Log ind
            </button>
        </form>
    </div>
    <app-help-note></app-help-note>
</div>