<div class="card m-3" *ngIf="!loading">
  <div class="card-header">
    <div class="col-lg-12 col-sm-12 mt-3 mb-3 text-center">
      <h1>{{ fund.name }}</h1>
    </div>

    <div class="p-3 rounded-3 row bg-color-secondary text-white">
      <div class="col-sm-1 col-lg-1 text-center">
        <div class="row">
          <div class="col-12 text-center"></div>
        </div>
        <div class="row">
          <div class="col-12 text-center"></div>
        </div>
      </div>

      <div class="col-sm-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 text-center">
            Indre værdi pr. {{ fund?.navdate }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            {{ fund?.nav | currency : "DKK" : "symbol" : "1.2-2" }}
          </div>
        </div>
      </div>

      <div class="col-sm-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 text-center">Afkast i dag</div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            {{ fund?.r_1d | number : "1.2-2" }}%
          </div>
        </div>
      </div>

      <div class="col-sm-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 text-center">Afkast år til dato</div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            {{ fund?.r_ytd | number : "1.2-2" }}%
          </div>
        </div>
      </div>

      <div class="col-sm-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 text-center">Din købskurs/salgskurs</div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            {{ fund?.subscription | currency : "DKK" : "symbol" : "1.2-2" }}
          </div>
        </div>
      </div>
      <div class="col-sm-3 col-lg-2 text-white bg-color-secondary">
        <select
          class="form-select"
          (change)="selectOption()"
          [(ngModel)]="selDoc"
        >
          <option selected disabled [ngValue]="null">-- Dokumenter --</option>
          <option [ngValue]="document" *ngFor="let document of fund?.documents">
            {{ document.name }}
          </option>
        </select>
      </div>
      <div class="col-sm-0 col-lg-1 text-center">
        <div class="row">
          <div class="col-12 text-center"></div>
        </div>
        <div class="row">
          <div class="col-12 text-center"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
        <div class="row">
          <div class="col-12">
            <h2 class="bg-secondary text-white border-3 p-1 mb-3">
              Mål og investeringspolitik
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 my-auto" [innerHTML]="fund?.description"></div>
        </div>
        <div class="col-4 m-3">
          <img
            [src]="getLogo(fund)"
            class="img-fluid"
            alt="{{ fund?.administrator }} logo"
          />
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <h2 class="bg-secondary text-white border-3 p-1 mb-3">Stamdata</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-striped">
                <tbody>
                  <tr class="">
                    <td colspan="2" class="text-center type">Fakta</td>
                  </tr>
                  <tr>
                    <th class="col-sm-8 col-md-6 col-lg-6">Profil</th>
                    <td class="col-sm-4 col-md-6 col-lg-6">
                      {{ fund?.profile }}
                    </td>
                  </tr>
                  <tr>
                    <th>Type</th>
                    <td>{{ fund?.investment_area }}</td>
                  </tr>
                  <tr>
                    <th>Område</th>
                    <td>{{ fund?.geography }}</td>
                  </tr>
                  <tr>
                    <th>Fondskode</th>
                    <td>{{ fund?.isin }}</td>
                  </tr>
                  <tr>
                    <th>Børsnoteret</th>
                    <td>{{ fund?.listed }}</td>
                  </tr>
                  <tr>
                    <th>Startdato</th>
                    <td>{{ fund?.start_date }}</td>
                  </tr>
                  <tr>
                    <th>Afdeling under</th>
                    <td>{{ fund?.umbrella }}</td>
                  </tr>
                  <tr>
                    <th>Hjemsted</th>
                    <td>{{ fund?.fund_domicile }}</td>
                  </tr>
                  <tr>
                    <th>Udbyttebetalende</th>
                    <td>{{ fund?.fund_dividend }}</td>
                  </tr>
                  <tr>
                    <th>{{ fund?.manager_title }}</th>
                    <td>{{ fund?.manager }}</td>
                  </tr>
                  <tr>
                    <th>Investeringsforvaltningsselskab</th>
                    <td>{{ fund?.administrator }}</td>
                  </tr>
                  <tr>
                    <th>Investeringsforvaltningsselskab hjemsted</th>
                    <td>{{ fund?.administrator_domicile }}</td>
                  </tr>
                  <tr>
                    <th>Depotbank</th>
                    <td>{{ fund?.custodian }}</td>
                  </tr>
                  <tr>
                    <th>Benchmark</th>
                    <td>{{ fund?.benchmark }}</td>
                  </tr>
                  <tr>
                    <th>Stykstørrelse</th>
                    <td>{{ fund?.unit_size }}</td>
                  </tr>
                  <tr>
                    <th>Beviser udstedt i</th>
                    <td>{{ fund?.currency }}</td>
                  </tr>
                  <tr>
                    <th>Prismetode</th>
                    <td>{{ fund?.price_method }}</td>
                  </tr>
                  <tr>
                    <th>Typisk investeringshorisont</th>
                    <td>{{ fund?.investment_period }} år</td>
                  </tr>
                  <tr>
                    <th>Produkttype</th>
                    <td>{{ fund?.product_type }}</td>
                  </tr>
                  <tr>
                    <th>ESG-kategori</th>
                    <td>{{ fund?.esg }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <h2 class="p-1 bg-secondary text-white border-3 mb-3">Omkostninger</h2>
        <p>
          Forventede samlede omkostninger i indeværende regnskabsår. De samlede
          omkostninger omfatter engangsomkostninger, løbende omkostninger og
          yderligere omkostninger.
        </p>
        <div class="table-responsive">
          <table class="table table-bordered">
            <tr>
              <td colspan="2" class="type text-center">Løbende omkostninger</td>
            </tr>
            <tr>
              <td class="col-5">Porteføljetransaktionsomkostninger</td>
              <td *ngIf="fund?.transaction_cost_pct !== null">
                {{ fund?.transaction_cost_pct | number : "1.2-2" }} %
              </td>
            </tr>
            <tr>
              <td>Andre løbende Omkostninger</td>
              <td>
                {{ fund?.administration_cost_pct | number : "1.2-2"
                }}<span *ngIf="fund?.administration_cost_pct > 0">%</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered">
            <tr>
              <td colspan="2" class="type text-center">Engangsomkostninger</td>
            </tr>
            <tr>
              <td class="col-5">Entry-omkostninger (emissionstillæg)</td>
              <td>
                {{ fund?.subscription_cost_pct | number : "1.2-2"
                }}<span *ngIf="fund?.subscription_cost_pct > 0">%</span>
              </td>
            </tr>
            <tr>
              <td>Exit-omkostninger (indløsningsfradrag)</td>
              <td>
                {{ fund?.redemption_cost_pct | number : "1.2-2"
                }}<span *ngIf="fund?.redemption_cost_pct > 0">%</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered">
            <tr>
              <td colspan="2" class="type text-center">
                Yderligere omkostninger
              </td>
            </tr>
            <tr>
              <td class="col-5">Resultatgebyrer</td>
              <td *ngIf="fund?.performance_fee > 0">
                {{ fund?.performance_fee | number : "1.2-2" }} %
              </td>
              <td *ngIf="fund?.performance_fee === null">-</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <h2 class="p-1 mb-3 bg-secondary text-white border-3">
          Risikoindikator
        </h2>
        <app-risc-indicator [risk_level]="fund?.fm_risk"></app-risc-indicator>
        <div class="mt-3 mb-3">
          <p>
            Risikoindikatoren angiver dette produkts risikoniveau i forhold til
            andre produkter.
          </p>
          Den viser sandsynligheden for, at produktet vil tabe penge på grund af
          bevægelser i markedet, eller fordi vi ikke er i stand til at betale
          dig. Risikoniveauet beregnes på baggrund af, at du holder produktet i
          hele anbefalingsperioden.
          <p>
            Risikoindikatorskalaen går fra 1 – 7, hvor 1 er lavest risiko
            niveau.
          </p>

          <p>
            Læs yderligere om produktet og risikoindikatoren
            <a class="link" target="_new" href="{{ fund?.link_priip }}">her</a>.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <h2 class="p-1 mb-3 bg-secondary text-white border-3">
          Sammensætning sektorer
        </h2>
        <div class="row mb-3 mt-3">
          <div *ngIf="fund?.exposure_delay > 0" class="col-12">
            Fordelingen sker på baggrund af porteføljens beholdning – bemærk at
            beholdningerne er forsinket med {{ fund?.exposure_delay }} dage.
          </div>
          <div class="table-responsive mt-3">
            <table
              class="table table-bordered table-striped rounded rounded-3 overflow-hidden"
            >
              <thead>
                <tr class="type text-white">
                  <th class="col-6">Type</th>
                  <th class="col-6">Aktie</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngIf="fund?.composition?.length === 0 || !fund?.composition"
                >
                  <td colspan="2">Der er desværre ingen data at vise.</td>
                </tr>

                <tr *ngFor="let composition of fund?.composition">
                  <td>{{ composition.type }}</td>
                  <td>
                    {{ composition.share | number : "1.1-2" }}
                    <span *ngIf="composition.share > 0">%</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <h2 class="p-1 mb-3 bg-secondary text-white border-3">
          Top 10 positioner
        </h2>
        <div class="row">
          <div *ngIf="fund?.exposure_delay > 0" class="col-12">
            Bemærk at beholdningerne er forsinket med
            {{ fund?.exposure_delay }} dage.
          </div>

          <div class="table-responsive mt-3">
            <table
              class="table table-bordered table-striped rounded rounded-3 overflow-hidden"
            >
              <thead>
                <tr class="type text-white">
                  <th col="col-8">Navn</th>
                  <th col="col-2">Isin</th>
                  <th col="col-1">Type</th>
                  <th col="col-1">%</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="fund?.top10?.length === 0 || !fund?.top10">
                  <td colspan="4">Der er desværre ingen data at vise.</td>
                </tr>
                <tr *ngFor="let top10 of fund?.top10">
                  <td>{{ top10?.name }}</td>
                  <td>{{ top10?.isin }}</td>
                  <td>{{ top10?.type }}</td>
                  <td>{{ top10?.pct | number : "1.2-2" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-spinner [spinning]="loading"></app-spinner>
