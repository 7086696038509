<div class="px-3 pt-3 rounded-3 type">
  <div class="row">
    <div class="col-lg-3"></div>
    <div class="col-lg-3 col-sm-6">
      <strong><div>Lavere risiko</div></strong>
    </div>
    <div class="col-lg-3 col-sm-6">
      <strong><div class="text-end">Højere risiko</div></strong>
    </div>
    <div class="col-lg-3"></div>
  </div>
  <div class="row">
    <div class="col-3"></div>

    <div class="col-lg-6 col-sm-12 col-md-12  text-center">
      <img
        style="width: 100%"
        src="../../assets/icons/_arrow_left_right_icon.svg"
      />
    </div>
    <div class="col-3"></div>
  </div>
  <div class="row">
    <div class="col-lg-3"></div>
    <div class="col-lg-3 col-sm-6">
      <p class="font-weight-bold">Typisk lavere afkast</p>
    </div>
    <div class="col-lg-3 col-sm-6">
      <p class="text-end font-weight-bold">Typisk højere afkast</p>
    </div>
    <div class="col-3"></div>
  </div>
  <div class="row justify-content-center">
    <div
      [ngClass]="{
        'is-primary-color': risk_level === 1,
        'bg-white': risk_level === 1,
        'is-white, bg-primary': risk_level !== 1
      }"
      class="col-1 text-center border-top-3"
    >
      1
    </div>
    <div
      [ngClass]="{
        'is-primary-color': risk_level === 2,
        'bg-white': risk_level === 2,
        'is-white, bg-primary': risk_level !== 2
      }"
      class="col-1 text-center border-top-3"
    >
      2
    </div>
    <div
      [ngClass]="{
        'is-primary-color': risk_level === 3,
        'bg-white': risk_level === 3,
        'is-white, bg-primary': risk_level !== 3
      }"
      class="col-1 text-center border-top-3"
    >
      3
    </div>
    <div
      [ngClass]="{
        'bg-white': risk_level === 4,
        'is-primary-color': risk_level === 4,
        'is-white, bg-primary': risk_level !== 4
      }"
      class="col-1 text-center border-top-3"
    >
      4
    </div>
    <div
      [ngClass]="{
        'is-primary-color': risk_level === 5,
        'bg-white': risk_level === 5,
        'is-white, bg-primary': risk_level !== 5
      }"
      class="col-1 text-center border-top-3"
    >
      5
    </div>
    <div
      [ngClass]="{
        'is-primary-color': risk_level === 6,
        'bg-white': risk_level === 6,
        'is-white, bg-primary': risk_level !== 6
      }"
      class="col-1 text-center border-top-3"
    >
      6
    </div>
    <div
      [ngClass]="{
        'bg-white': risk_level === 7,
        'is-primary-color': risk_level === 7,
        'is-white, bg-primary': risk_level !== 7
      }"
      class="col-1 text-center"
    >
      7
    </div>
  </div>
</div>
