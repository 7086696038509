import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalErrorHandlerService } from './global-error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  sharedUrl: string = environment.apiUrl;
  loading: boolean;
  constructor(
    private http: HttpClient,
    public globalErrorHandlerService: GlobalErrorHandlerService
  ) { }

  base64ToArrayBuffer(data: any) {
    if (data?.includes('base64,')) {
      data = data?.split('base64,')[1];
    }
    const binaryString = window?.atob(data);
    const binaryLen = binaryString?.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  download(selFile, type?) {
    this.loading = true;
    const arrBuffer = this.base64ToArrayBuffer(selFile?.content);
    const file = new Blob([arrBuffer], { type: selFile?.mimetype || type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = selFile.name;
    this.loading = false;
    link.setAttribute('target', '_blank');
    link.click();
  }
}
