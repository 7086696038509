import { Component } from '../../shared/component';
import { html } from 'cedk';
import { Queue } from './utils.js';

const queue = new Queue();

/**
 * The `<fm-toast>` element.
 * 
 * A toast is a small, single-line notification.
 * On desktop, `<fm-toast>` appears in the bottom-left corner of the viewport.
 * On mobile, `<fm-toast>` appears in the bottom-center of the viewport.
 * 
 * `<fm-toast>` can be instantiated as a regular element, or it can be
 * created with `ToastElement.create`.
 * 
 * @example
 * const toast = document.createElement('fm-toast');
 * // or
 * const toast = new ToastElement();
 * // or
 * const toast = ToastElement.create('message');
 * 
 */
export class Toast extends Component {
  static define() {
    super.define('fm-toast');
  }

  static get style() {
    return {
      ':host': {
        'display': 'flex',
        'align-items': 'center',
        'justify-content': 'space-between',
        'flex': '1',
        'position': 'fixed',
        'left': '0',
        'bottom': '0',
        'min-height': '48px',
        'min-width': '288px',
        'border-radius': '2px',
        'margin': '8px',
        'padding': '4px 24px',
        'background': '#263238',
        'z-index': '999',
        'color': 'white',
        'transition': [
          'transform 0.25s',
          'opacity 0.25s',
          'visibility 0s 0.25s'
        ].join(','),
        'transition-timing-function': 'cubic-bezier(0.25, 0.8, 0.25, 1)',
        'visibility': 'hidden',
        'opacity': '0',
        'transform': 'translateY(50%)'
      },
      ':host([opened])': {
        'opacity': '1',
        'visibility': 'visible',
        'transform': 'translateY(0)',
        'transition-delay': '0s'
      },
      ':host > ::slotted(button)': {
        'background': 'none',
        'border': 'none',
        'text-transform': 'uppercase',
        'font-weight': '700',
        'cursor': 'pointer',
        'color': '#40C4FF',
        'padding': '8px 16px'
      },
      ':host > ::slotted(fm-button)': {
        'background': 'none',
        'border': 'none',
        'text-transform': 'uppercase',
        'font-weight': '700',
        'cursor': 'pointer',
        'color': '#40C4FF',
        'padding': '8px 16px'
      },
      '*': {
        'box-sizing': 'border-box'
      }
    }
  }

  static get template() {
    return html`<slot></slot>`;
  }

  static get properties() {
    return {
      duration: {
        type: Number
      },
      opened: {
        type: Boolean,
        reflectToAttribute: true
      }
    }
  }

  /**
   * Create a toast and append it to the document body and,
   * optionally, remove it again after the given duration.
   * @param {string} text The text in the toast.
   * @param {number} [duration] The duration of the toast.
   * If not specified, the toast will remain visible until 
   * `element.close` is called.
   * @returns {ToastElement}
   */
  static create(text, duration) {
    const toast = document.createElement('fm-toast');
    toast.textContent = text;
    toast.duration = duration;
    document.body.appendChild(toast);
    toast.addEventListener('done', () => {
      toast.remove();
    }, { once: true });
    requestAnimationFrame(() => {
      toast.open();
    });
    return toast;
  }

  constructor() {
    super();
    this.opened = false;
    this.pause = this.pause.bind(this);
    this.resume = this.resume.bind(this);
    this.close = this.close.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('mouseenter', this.pause);
    this.addEventListener('mouseleave', this.resume);
    this._button = this.querySelector('button');
    if (this._button) {
      this._button.addEventListener('focus', this.pause);
      this._button.addEventListener('blur', this.resume);
      this._button.addEventListener('click', this.close);
    }
  }

  disconnectedCallback() {
    if (this._button) {
      this._button.removeEventListener('focus', this.pause);
      this._button.removeEventListener('blur', this.resume);
      this._button.removeEventListener('click', this.close);
    }
    this.removeEventListener('mouseenter', this.pause);
    this.removeEventListener('mouseleave', this.resume);
    super.disconnectedCallback();
  }

  /**
   * Add the element to the queue of toast messages.
   * When the element is first in the queue, it will be served.
   */
  open() {
    queue.add(this);
  }

  /**
   * Animate the element into the viewport.
   */
  serve() {
    setTimeout(() => {
      this.opened = true;
      if (this.duration) {
        this.timeOut = setTimeout(() => {
          if (!this.isPaused) {
            this.close();
          } else {
            this.isExpired = true;
          }
        }, this.duration);
      }
    }, 0);
  }

  /**
   * Animate the elemnt out of the viewport.
   */
  close() {
    clearTimeout(this.timeOut);
    this.opened = false;
    this.addEventListener('transitionend', () => {
      this.dispatchEvent(new Event('done'));
      queue.next();
    }, { once: true });
  }

  /**
   * Prevent the element from exiting the viewport until resumed.
   * The elements internal timer will continue ticking.
   * If the elements internal timer elapses during a pause,
   * the element will immediately exit the viewport.
   */
  pause() {
    this.isPaused = true;
  }

  /**
   * Allow the element to exit the viewport once its' timer elapses.
   * The element will immediately exit if the timer is already elapsed.
   */
  resume() {
    this.isPaused = false;
    if (this.isExpired) {
      clearTimeout(this.timeOut);
      this.close();
    }
  }
}

export { Toast as ToastElement };