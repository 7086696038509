import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Country } from '@sharedmodels/country.model';
import { Custody } from '@sharedmodels/custody.model';
import { Custodyuniverse } from '@sharedmodels/custodyuniverse.model';
import { Resp } from '@sharedmodels/resp.model';
import { CustodyService } from 'src/app/services/custody.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-vso-account-choice',
  templateUrl: './vso-account-choice.component.html',
})
export class VsoAccountChoiceComponent implements OnInit {
  showChildInfo: boolean = false;
  vsoinfo: FormGroup;
  custodyuniverses: Custodyuniverse[];
  custodyuniverse = <Custodyuniverse>{};
  custodies: Custody[];
  custody = <Custody>{};
  countries = new Array<Country>();
  bolandcount = <any>[];
  pristatcount = <any>[];
  sekstatcount = <any>[];
  loading: boolean = false;
  info: string;
  error: string;
  @Input() model_id: number;

  constructor(
    private formBuilder: FormBuilder,    
    private dialogRef: MatDialogRef<VsoAccountChoiceComponent>,
    public custodyService: CustodyService,
    public sharedService: SharedService
  ) {}

  ngOnInit(): void { 
    this.custody.file = null;
    
    this.vsoinfo = this.formBuilder.group({
      account_number: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
          Validators.pattern('^[-0-9 ]*$'),
        ],
      ],
    });
  } 
  
  createNewVSO() {
    this.loading = true;
    this.info = '';
    this.error = '';    
    this.custody.model_id = this.model_id;
    this.custody.account_number = this.vsoinfo.get('account_number').value;
    if (!this.custody.file) {
      this.loading = false;
      this.info = 'Du mangler at uploade en fil';
      return;
    }
    if(this.custody.account_number && this.custody.file){
      this.createCustody(this.custody);
      //this.dialogRef.close();
    } 
  }

  selectOption(event) {
    if (event.target.value) {
      this.custody.model_id = event.target.value;
    }
  }

  public sendFile(file: any) {
    this.custody.file = file;
  }
  

  createCustody(custody: Custody) {
    this.loading = true;
    this.custody.custodytype_id = 2;
    this.custody.owner_cvr = null;
    this.custody.model_id = custody.model_id;
    this.custodyService.createCustodies(this.custody).subscribe((resp: Resp) => {
      this.loading = false;      
      if (resp?.status?.toUpperCase()==="OK") {         
        this.info = 'Din vso konto er nu oprettet.';
        this.dialogRef.close(this.info);
      } else {
        this.dialogRef.close(this.error);
        this.error = resp?.message || 'Der er desværre sket en fejl.';
      }
    }),
      (error) => {
        this.loading = false;
        this.error = error;
      };
   
  }  
}
