<div class="my-3 col-12 col-lg-8">
  <div class="card">
    <div class="card-body">
      <h2>Upload legitimation</h2>
      <div class="mb-3 row">
        <form [formGroup]="uploadinfo" (submit)="upload()">
          <div class="">
            <label for="picture" *ngIf="child" class="form-label"
              >Upload dokumentation for dit barn i form af kopi af barnets sundhedskort. </label
            >   
            <label for="picture" *ngIf="vso" class="form-label"
              >Upload venligst kopi af engagement, kontoudtog eller andet, så vi har dokumentation for at kontoen tilhører VSO-ordningen.</label
            > 
            <label for="picture" *ngIf="!vso && !child" class="form-label"
              >Upload venligst kopi af gyldigt billede-id, f.eks. kørekort eller
              pas. </label
            >         
            <input
              class="form-control input-width"
              (change)="getFile($event)"
              formControlName="picture"
              type="file"
              id="picture"
              required
              accept="image/*"
            />
          </div>
            <div class="mb-3 row">
              <div class="col-auto">
                <button
                  [disabled]="!uploadinfo.valid || loading"
                  class="mt-3 mb-3 btn btn-primary"
                >
                  Upload
                </button>
              </div>
              <div class="col-auto">
                <div class="text-success m-3">{{ info }}</div>
                <div class="text-danger m-3">{{ error }}</div>
              </div>
        
          </div>
        </form>
      </div>
      <img [src]="url" height="200"> <br/>
      <h2 *ngIf="files?.length > 0">Uploadede filer</h2>
      <div class="row">
        <div class="col-auto" *ngFor="let file of files">
          <h3>{{ file.name }}</h3>
          <object
            class="rounded"
            width="240"
            height="240"
            type="{{ file.mimetype }}"
            [data]="file.url"
          ></object>
        </div>
      </div>
    </div>
  </div>
</div>
<app-spinner [spinning]=loading></app-spinner>
