import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { Upload } from 'model-library';

@Injectable({
  providedIn: 'root',
})
export class AmlService {
  callbackUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    public globalErrorHandlerService: GlobalErrorHandlerService
  ) { }

  getQuestions() {
    return this.http
      .get(this.callbackUrl + '/user/questions', {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  saveAnswer(answer, id) {
    return this.http
      .post<FormData>(this.callbackUrl + '/user/questions/' + id, answer, {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  deleteAnswer(id) {
    return this.http
      .delete<FormData>(this.callbackUrl + '/user/answers/' + id, {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(() =>
            this.globalErrorHandlerService.handleError(err)
          );
        })
      );
  }

  fileUpload(id, file: Upload) {
    return this.http
      .post(this.callbackUrl + '/user/answers/' + id + '/upload', file, {
        headers: new HttpHeaders({
          fundmarkettoken: sessionStorage?.getItem('APIToken1') as string,
        }),
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          this.globalErrorHandlerService.handleError(err);
          return of();
        })
      );
  }
}
