import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-risc-indicator',
  templateUrl: './risc-indicator.component.html',
})
export class RiskIndicatorComponent{
  private _risk_level: number;

  @Input()
  public set risk_level(risk_level: number) {
    this._risk_level = risk_level;
  }

  public get risk_level() {
    return this._risk_level;
  }

  constructor() {}

  
}
