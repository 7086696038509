import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-mitid-button',
    templateUrl: './mitid-button.component.html',
    styleUrls: ['./mitid-button.component.css'],
})
export class MitIDButtonComponent {
    @Input()
    disabled: boolean;

    @Input()
    loading: boolean;
}
